<template>
  <div class="content-view">
    <div class="content-view-block">
      <p>
        <b>Supervisor: </b>
        <span>{{ supervisorFullName }}</span>
      </p>
      <p>
        <b>Confirmation Date</b>
        <span>{{ confirmationDate }}</span>
      </p>
      <p>
        <b>CDPH 530 Form PDF</b>
        <a-button
          icon="download"
          @click="downloadCDPH530"
        >
          Download
        </a-button>
      </p>
    </div>
  </div>
</template>

<script>
import Util from "@/util";
import api from "@/api";
import {notification, Button} from "ant-design-vue";

export default {
  components: {
    'a-button': Button
  },
  props: ['confirmation'],
  mixins: [api],
  computed: {
    supervisorFullName() {
      return this.confirmation != null ?
        this.getFullName(this.confirmation.supervisor.first_name, this.confirmation.supervisor.last_name) :
        undefined;
    },
    confirmationDate() {
      return this.confirmation != null ?
        Util.convertDateTimeToCurrentTimeZoneFormattedString(this.confirmation.confirmed_at)
        : undefined;
    }
  },
  methods: {
    getFullName(firstName, lastName) {
      return Util.combineAssociateName(firstName, lastName);
    },
    async downloadCDPH530() {
      await this.apiGetCDPH530(this.confirmation.id)
          .then((response) => {
            const data = response.data;

            if (data.link) {
              window.open(data.link, '_blank');
            } else {
              this.showCDPH530DownloadErrorNotification();
            }
          })
          .catch((response) => this.showCDPH530DownloadErrorNotification())
    },

    showCDPH530DownloadErrorNotification() {
      notification['warning']({
        message: 'CDPH 530 Download Error',
        description: 'Please try again later'
      })
    }
  }
}
</script>

<style scoped>
.content-view {
  padding: 10px 0;
}
.content-view > * {
  max-width: unset;
  width: 100%;
}
</style>
