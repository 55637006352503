<template>
  <div>
    <div class="flex flex-column gap-4 px-4">
      <div>
        <h4>Active Payment Type</h4>
        <span>{{ currentPaymentTypeName }}</span>
        <h4>Time Zone</h4>
        <span class="time-zone-select-group">
              <a-select
                class="time-zone-select"
                v-model="selectedTimeZone"
                @change="onTimeZoneChange"
                show-search
                filter-option
              >
              <a-select-option v-for="zone in timeZones" :key="zone" :value="zone">
                {{ zone }}
              </a-select-option>
            </a-select>
              <a-button icon="reload" @click="resetTimeZone">Reset</a-button>
            </span>
      </div>
      <div>
        <h4>Your Companies</h4>
        <all-projects/>
      </div>
      <div>
        <h4>Your Default Signature</h4>
        <div class="flex flex-column gap-3">
          <signature
            :key="signatureFieldKey"
            v-if="adminAssociateSignature || creatingNewSignature"
            :associate-name="adminAssociateName"
            :associate-email="adminAssociateEmail"
            :associate-phone="adminAssociatePhoneNumber"
            :available-signature="creatingNewSignature ? undefined : adminAssociateSignature"
            :signature-date="adminAssociateSignature.signature_date"
            @save-signature="handleSaveNewSignature"
          />
          <span v-else>Default signature not found</span>
          <div class="flex gap-2">
            <a-button
              v-if="!creatingNewSignature"
              type="primary"
              class="w-3"
              @click="initNewSignature"
            >
              {{ adminAssociateSignature ? 'Change Signature' : 'Create Signature' }}
            </a-button>
            <a-button v-else @click="endCreateNewSignature">
              Cancel
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Select, Button, notification} from "ant-design-vue";
import moment from "moment-timezone";
import {mapMutations} from "vuex";
import api from "@/api";
import store from "@/store";
import AllProjects from "@/views/all-projects.vue";
import signature from "@/components/signature.vue";
import Util from "@/util";

export default {
  components: {
    signature,
    AllProjects,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-button': Button
  },
  mixins: [api],
  data() {
    return {
      selectedTimeZone: undefined,
      // selectedPaymentType: undefined,
      creatingNewSignature: false,
      signatureFieldKey: 0,

      timeZones: moment.tz.names()
    }
  },
  computed: {
    paymentTypes() {
      return this.$store.state.applicationState.paymentTypes;
    },
    currentPaymentTypeName() {
      return this.$store.state.applicationState.currentPaymentTypeName ?? '-';
    },
    adminAssociateName() {
      return Util.combineAssociateName(
        this.$store.state.applicationState.associate.first_name,
        this.$store.state.applicationState.associate.last_name
      );
    },
    adminAssociateEmail() {
      return this.$store.state.applicationState.associate.email
    },
    adminAssociatePhoneNumber() {
      return this.$store.state.applicationState.associate.phone
    },
    adminAssociateSignature() {
      return this.$store.state.applicationState.associate.signature;
    }
  },
  methods: {
    ...mapMutations({
      setStoreTimeZone: 'setTimeZone'
    }),
    onTimeZoneChange(newTimeZone) {
      this.setStoreTimeZone(newTimeZone);
      window.location.reload();
    },
    resetTimeZone() {
      const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.selectedTimeZone = defaultTimeZone;
      this.setStoreTimeZone(defaultTimeZone);
    },
    initNewSignature() {
      this.creatingNewSignature = true;
      this.signatureFieldKey++;
    },
    endCreateNewSignature() {
      this.signatureFieldKey++;
      this.creatingNewSignature = false;
    },
    async handleSaveNewSignature({isEmpty, data}) {
      if (isEmpty) {
        notification['warning']({
          message: 'Signature Upload Error',
          description: 'Signature that you provided is empty. Please draw signature again'
        })

        return;
      }

      await this.apiSignatureSave(data)
        .then((response) => {
          if (response.body.error_code != 0) {
            notification['warning']({
              message: 'Signature Upload Error',
              description: 'File with your signature wasn\'t uploaded because of error. Please try again later'
            })
            return;
          }

          notification['success']({
            message: 'New Signature Uploaded Successfully',
          })

          store.dispatch('loadApplicationState');

          this.endCreateNewSignature();
        })
    }
  },
  mounted() {
    this.selectedTimeZone = this.$store.state.applicationState.timeZone ?? undefined;
  }
}
</script>

<style scoped>
.time-zone-select-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.time-zone-select {
  width: 250px;
}
</style>
