<template>
  <div id="app">
    <app-layout>
      <router-view/>
    </app-layout>
  </div>
</template>

<script>
import api from "@/api";
import AppLayout from "@/components/app-layout.vue";
import {notification} from "ant-design-vue";

export default {
  components: {
    'app-layout': AppLayout
  },
  data() {
    return {
      version: null,
      checkInterval: 300000 // 5 minutes
    }
  },
  mixins: [api],
  methods: {
    checkVersion() {
      if (this.$store.state.applicationState.authError) {
        return;
      }

      this.apiGetFrontendVersion()
        .then((response) => {
          const data = response.data;

          if (!data.version) {
            return;
          }

          if (this.version && this.version != data.version) {
            notification['info']({
              message: 'Web Application Update',
              description: 'The page will refresh in 5 minutes',
              duration: 300 // also 5 minutes
            })

            setTimeout(() => window.location.reload(), this.checkInterval);
          }

          this.version = data.version;
        })
    }
  },
  mounted() {
    this.interval = setInterval(this.checkVersion, this.checkInterval);
  }
}
</script>

<style>
  @import "~semantic-ui-css/semantic.min.css";
  @import "./assets/css/main.css";
  @import "~primeflex/primeflex.css";

#app {
  font-family: 'Proxima Nova', Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
