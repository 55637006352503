<template>
  <div>
    <h2 class="view-header">Notifications</h2>
    <div class="table_controls_and_data">
      <div class="table_data_wrapper"></div>
      <div class="table_controls_wrapper">
        <div class="table_params">
          <a-button :disabled="tableData.length == 0 && !notFound" @click="clearData">Clear</a-button>
        </div>
        <div class="table_predefined_filters">
          <a-select
            ref="userSelect"
            v-model:value="selectedEmployees"
            show-search
            :allowClear="true"
            placeholder="Associate"
            style="min-width: 180px"
            mode="multiple"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :default-active-first-option="false"
            :dropdown-match-select-width="false"
            :disabled="loading"
            :options="foundEmployees"
            :loading="searchingUsers"
            @search="fetchUsers"
            @change="handleSearchUsersChange"
          />

          <a-date-picker
            show-time
            v-model="dateFrom"
            :disabled-date="disabledStartDate"
            :format="dateFormat"
            placeholder="Start Date"
            style="flex: 1 1 0"
            :disabled="loading"
          />
          <a-date-picker
            show-time
            v-model="dateTo"
            :disabled-date="disabledEndDate"
            :format="dateFormat"
            placeholder="End Date"
            style="flex: 1 1 0"
            :disabled="loading"
          />
        </div>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="fcm_accepted" slot-scope="text, record">
        <a-icon :type="record.fcm_accepted ? 'check' : 'close'"/>
      </span>
    </a-table>
  </div>
</template>

<style scoped>
.ant-table td {
  white-space: nowrap;
}
</style>

<script>
import api from "@/api";
import Util from "@/util";
import moment from "moment-timezone";
import store from '@/store';
import JsonExcel from "vue-json-excel";
import {Button, DatePicker, Select, Menu, Dropdown, Icon, Table} from "ant-design-vue";

export default {
  components: {
    'a-button': Button,
    'a-date-picker': DatePicker,
    'a-select': Select,
    'a-menu': Menu,
    'a-menu-item': Menu.Item,
    'a-icon': Icon,
    'a-dropdown': Dropdown,
    'a-table': Table,
    'download-excel': JsonExcel,
  },
  data: () => {
    return {
      loading: false,
      searchingUsers: null,

      tableData: [],

      foundEmployees: [],
      selectedEmployees: [],

      dateFrom: null,
      dateTo: null,
      notFound: false,

      dateFormat: store.state.applicationState.dateFormat,
      sendingDateFormat: 'YYYY-MM-DD HH:mm:ss',

      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          sorter: true,
          width: 90
        },
        {
          title: 'User ID',
          dataIndex: 'user_id',
          key: 'user_id',
          sorter: true,
          width: 120
        },
        {
          title: 'First Name',
          dataIndex: 'first_name',
          key: 'first_name',
          sorter: true
        },
        {
          title: 'Last Name',
          dataIndex: 'last_name',
          key: 'last_name',
          sorter: true
        },
        {
          title: 'Header',
          dataIndex: 'header',
          key: 'header',
          sorter: true
        },
        {
          title: 'Message',
          dataIndex: 'message',
          key: 'message',
          sorter: true
        },
        {
          title: 'Sending Date',
          dataIndex: 'sending_date',
          key: 'sending_date',
          sorter: true,
          width: 180
        },
        {
          title: 'FCM Accepted',
          dataIndex: 'fcm_accepted',
          scopedSlots: {customRender: 'fcm_accepted'},
          align: 'center',
          sorter: true,
          width: 100
        },
      ],

      pagination: {
        pageSize: 50,
        total: 0,
        current: 1
      },

      table_height: null
    }
  },

  watch: {
    selectedEmployees() {
      this.loadData();
    },
    dateFromString() {
      this.loadData();
    },
    dateToString() {
      this.loadData();
    }
  },

  mixins: [api],

  computed: {
    dateFromString() {
      return this.dateFrom ? moment.utc(this.dateFrom).format(this.sendingDateFormat) : null
    },
    dateToString() {
      return this.dateTo ? moment.utc(this.dateTo).format(this.sendingDateFormat) : null
    },
  },

  methods: {
    loadData(sorter) {
      this.notFound = false;
      this.tableData = [];

      this.loading = true;

      Object.assign(this.$data.columns, this.$options.data().columns)

      this.apiGetPushNotifications(this.selectedEmployees, this.dateFromString, this.dateToString, this.pagination, sorter)
        .then((response) => {
          if (response.data && response.data.push_notifications && response.data.push_notifications.length > 0) {
            this.tableData = response.data.push_notifications.map(push => ({
              ...push,
                sending_date: Util.convertDateTimeToCurrentTimeZoneFormattedString(push.sending_date)
            }))
          }

          this.pagination = {
            ...this.pagination,
            total: response.data.total
          }

          if (this.tableData.length == 0) {
            this.notFound = true;
          }

          this.loading = false;
        })
        .catch((err) => console.error(err))
    },
    clearData() {
      this.notFound = false;
      this.dateFrom = null;
      this.dateTo = null;
      this.tableData = [];
      this.foundEmployees = [];
      this.selectedEmployees = [];
    },

    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    fetchUsers(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout)
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.searchingUsers = true;
        this.apiSearchAssociate(value).then((resp) => {
          this.foundEmployees = resp.data.associate_list.map(user => ({
            label: `[${user.user_id}] ${user.first_name} ${user.last_name}`,
            key: user.user_id,
            value: user.user_id
          }))
          this.searchingUsers = false;
        })
      }, 1000)
    },

    handleSearchUsersChange(value) {
      this.selectedEmployees = value;
      this.$refs.userSelect.blur();
    },

    handleTableChange(pagination, filters, sorter) {
      this.pagination = { ...pagination }
      this.loadData(sorter);
    }
  },

  mounted() {
    this.loadData();
  }
}
</script>
