import moment from 'moment-timezone';
import store from '@/store';

export default {
  saveItem(key, value) {
    if (this.isEmpty(value))
      this.removeItem(key)
    else
      sessionStorage.setItem(key, value)
  },
  readItem(key) {
    return sessionStorage.getItem(key)
  },
  removeItem(key) {
    return sessionStorage.removeItem(key)
  },

  convertDateTimeToCurrentTimeZoneFormattedString(dateTime) {
    if (this.isEmpty(dateTime)) {
      return null;
    }

    return this.convertDateTimeToCurrentTimeZone(dateTime).format(store.state.applicationState.dateTimeFormat);
  },

  convertDateTimeToCurrentTimeZoneWithFormat(dateTime, format) {
    if (this.isEmpty(dateTime)) {
      return null;
    }

    return this.convertDateTimeToCurrentTimeZone(dateTime).format(format);
  },

  convertDateTimeToCurrentTimeZone(dateTime) {
    if (this.isEmpty(dateTime)) {
      return null;
    }

    const timeZone = store.state.applicationState.timeZone;
    if (this.isEmpty(timeZone)) {
      console.error("Time zone is not defined");
      return null;
    }

    return moment.utc(dateTime).seconds(0).milliseconds(0).tz(timeZone);
  },

  convertDateTimeToUTC(dateTime) {
    if (this.isEmpty(dateTime))
      return null;

    return moment(dateTime).utc().toISOString().substring(0, 16);
  },

  getCurrentDateTimeInCurrentTimeZone(dateTime) {
    const timeZone = store.state.applicationState.timeZone;
    if (this.isEmpty(timeZone)) {
      console.error("Time zone is not defined");
      return null;
    }

    return moment.tz(timeZone);
  },

  compareDatesFirstLess(dateTime1, dateTime2) {
    let d1 = new Date(dateTime1)
    let d2 = new Date(dateTime2)
    return d1 < d2
  },
  compareDatesFirstLessOrEqual(dateTime1, dateTime2) {
    let d1 = new Date(dateTime1)
    let d2 = new Date(dateTime2)
    return d1 <= d2
  },

  convertDateForSearch(dayString, isFrom) {
    if (this.isEmpty(dayString)) return dayString
    let momentDate = moment(dayString, "MM/DD/YYYY")
    let dt = momentDate.toDate()
    if (isFrom)
      dt.setHours(0, 0, 0)
    else
      dt.setHours(23, 59, 59)
    //Here it is converting from local to UTC
    let ret = dt.toISOString().substring(0, 10)
    return ret
  },

  combineAssociateName(firstName, lastName) {
    if (!firstName || !lastName) {
      return '';
    }

    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();

    return this.capitalize(trimmedFirstName) + ' ' + this.capitalize(trimmedLastName);
  },

  handleServerError(error) {
    if (error && error.statusText) {
      alert('Error happened: ' + error.statusText);
    } else if (error) {
      alert('Error happened: ' + error);
    }
  },
  handleAPIcallResult(data, okFunction) {
    if (data.body.error_code == 0) {
      okFunction();
    } else {
      alert(data.body.msg);
    }
  },

  isEmpty(str) {
    return str === null || str == undefined || str == ''
  },
  isNumberEmpty(num) {
    return num === null || num == undefined
  },

  //str->decimal (db->ui)
  convertDbToMoney(centsStr) {
    if (!centsStr) return 0
    let cents = parseInt(centsStr)
    return cents / 100
  },

  //decimal->str (ui->db)
  convertMoneyToDb(money) {
    if (this.isEmpty(money)) return null
    return money * 100
  },

  getMapKeyByValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
      if (value === searchValue)
        return key;
    }
  },

  processInputName(inputValue) {
    return inputValue && inputValue.length > 1 ? inputValue.trim().replace(/ /g, ',') : inputValue;
  },

  countDuration(startMoment, endMoment, timeUnit) {
    const roundedStartMoment = startMoment.clone().startOf('minute');
    const roundedEndMoment = endMoment.clone().startOf('minute');

    return roundedEndMoment.diff(roundedStartMoment, timeUnit);
  },

  formatMinutesToHoursAndMinutesDuration(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);

    let formattedResult = '';

    if (hours !== 0) formattedResult += hours + 'h';
    if (hours && minutes) formattedResult += ' ';
    if (minutes !== 0 || totalMinutes === 0) formattedResult += minutes + 'm';

    return formattedResult;
  },

  getFormattedDuration(startMoment, endMoment) {
    return this.formatMinutesToHoursAndMinutesDuration(Math.abs(this.countDuration(startMoment, endMoment, 'minutes')));
  },

  capitalize(str) {
    if (typeof str === 'string' && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return str;
    }
  },

  splitAndCapitalize(str) {
    const words = str.split('_');
    const capitalizedWords = words.map(word =>
      this.capitalize(word)
    );

    return capitalizedWords.join(' ');
  },

  titleCase(str) {
    return str.toLowerCase().split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  },

  formatDatesListForTimeOffRequestTable(dates) {
    const format = store.state.applicationState.dateFormat;
    if (dates.length === 0) return '';

    if (dates.length === 1) {
      return moment(dates[0]).format(format);
    } else {
      const startDate = moment(dates[0]).format(format);
      const endDate = moment(dates[dates.length - 1]).format(format);
      return `${startDate} - ${endDate} (${dates.length} dates)`;
    }
  },

  formatDatesListForTimeOffRequestModal(dates) {
    const format = store.state.applicationState.dateFormat;

    return dates
      .map(date => {
        const momentDate = moment(date);
        const currentYear = moment().year();
        return momentDate.year() === currentYear
          ? momentDate.format('MMM DD')
          : momentDate.format('MMM DD YYYY');
      })
      .join('; ');
  },

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
};
