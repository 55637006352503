<template>
  <div>
    <h2 class="view-header">Manual Shift Authorizations</h2>

    <manual-shift-auths-filters
      :current-filters="filters"
      :visible="filtersModalVisible"
      :key="filtersModalKey"
      @apply="applyFilters"
    />

    <send-notification :associate="associateProfileToSendSms" @close="closeSmsModal" />

    <a-modal
      v-model="visibleAssociateShiftModal"
      width="950px"
      :footer="null"
      @cancel="closeAssociateShiftModal"
    >
      <associate-shift
        :key="associateShiftComponentKey"
        :shift-id="editingAssociateShiftId"
        :associate-number="employee_id"
        @on-add-or-edit="closeAssociateShiftModalWithChanges"
        @on-shift-deletion="openShiftDeletionConfirmationModal"
        @shift-exists="handleShiftExists"
      />
    </a-modal>
    <a-modal
      title="Associate Profile Edit"
      width="80%"
      :footer="null"
      v-model="associateProfileModalVisible"
      @ok="endEditAssociateProfile"
      @cancel="endEditAssociateProfile"
    >
      <div style="overflow-x: auto; width: 100%">
        <associate-profile :associate-number="editingAssociateNumber" @edited="handleEditedAssociateProfile"/>
      </div>
    </a-modal>
    <a-modal
      width="600px"
      title="Reason of Deny Authorization"
      v-model="denyReasonVisible"
      @ok="confirmDeny"
    >
      <shift-auth-deny-form ref="shiftAuthDenyForm" @reason="setResolveComment" @params="setDenyParams"/>
    </a-modal>
    <a-modal
      width="600px"
      title="Accept Authorization Comment"
      v-model="acceptCommentVisible"
      @ok="confirmAccept"
    >
      <a-input placeholder="Optional" v-model="resolveComment" />
    </a-modal>

    <div class="flex justify-content-end align-items-center gap-2">
      <a-button-group>
        <a-button
          icon="filter"
          :disabled="loading"
          @click="showFilterModal"
        />
        <a-button
          icon="undo"
          :disabled="loading || !enableResetButton"
          @click="resetFilters"
        />
      </a-button-group>

      <a-date-picker
        v-model="dayFrom"
        :disabled-date="disabledStartDate"
        :disabled="loading"
        format="MM/DD/YYYY"
        placeholder="Start Date"
        style="width: 100px"
      />
      <a-date-picker
        v-model="dayTo"
        :disabled-date="disabledEndDate"
        :disabled="loading"
        format="MM/DD/YYYY"
        placeholder="End Date"
      />
      <div class="flex flex-column">
        <div>Total: {{ total_shifts_with_auths || '...' }}</div>
      </div>
    </div>
    <a-table
      size="small"
      rowKey="shift.id"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      :expanded-row-render="expandedRowRender"
      :expand-row-by-click="true"
      @change="handleTableChange"
    >
      <span slot="date" slot-scope="text, record">
        {{ formatDateString(record.shift.date) }}
      </span>
      <span slot="associate" slot-scope="text, record" :key="record.photo_url">
        <grouped-associate-info
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.photo_url"
        />
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-button
            title="Show Authorizations"
            @click="acceptManualShiftAuth(record)"
          >
            Accept
          </a-button>
          <a-button
            title="Show Authorizations"
            @click="declineManualShiftAuth(record)"
          >
            Decline
          </a-button>
          <a-button
            title="Adjust Shift"
            @click="editShift(record.shift.id)"
          >
            Adjust
          </a-button>
          <a-button
            title="Edit Profile"
            @click="startEditAssociateProfile(record.associate.associate_number)"
          >
            Profile
          </a-button>
          <a-button
            id="send-msg-btn"
            :disabled="!record.associate.phone"
            @click="openSmsSendModal(record.associate)"
          >
            Message
          </a-button>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>

<script>

import moment from 'moment-timezone';
import ShiftAuthDenyForm from "@/components/shift-auth-deny-form.vue";
import SendNotification from "@/components/send-notification.vue";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Icon,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
  Switch
} from "ant-design-vue";
import api from "@/api";
import shiftsHelper from "@/helpers/shifts";
import store from "@/store";
import Util from '@/util';
import AssociateShift from "@/components/associate-shift.vue";
import AssociateProfile from "@/components/associate-profile.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import ManualShiftAuthsFilters from "@/components/manual-shift-auths-filters.vue";
const AuthStatus = Object.freeze({
  INITIAL: 'initial',
  ACCEPTED: 'accepted',
  DECLINED: 'declined'
});

const initialPagination = {
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
  pageSize: 50,
  total: 0,
  current: 1
};

const filtersBaseState = Object.freeze({
  associateNumber: undefined,
  authType: undefined,
  withScheduleError: false,
  assignedToMe: false,
});

export default {
  components: {
    SendNotification,
    'a-date-picker': DatePicker,
    'a-row': Row,
    'a-col': Col,
    'a-input': Input,
    'a-button': Button,
    'a-modal': Modal,
    'a-button-group': Button.Group,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-checkbox': Checkbox,
    'a-pagination': Pagination,
    'a-table': Table,
    'a-tag': Tag,
    'a-icon': Icon,
    'a-switch': Switch,
    'shift-auth-deny-form': ShiftAuthDenyForm,
    'associate-shift': AssociateShift,
    'associate-profile': AssociateProfile,
    'grouped-associate-info': GroupedAssociateInfo,
    'manual-shift-auths-filters': ManualShiftAuthsFilters
  },
  data() {
    return {
      tableData: [],

      workDateFormat: store.state.applicationState.dateFormat,
      projectId: store.state.applicationState.currentProject.id,

      loading: false,
      authorizationsListVisible: false,

      currentShiftRecord: undefined,
      currentShiftAuthsList: [],
      selectedCurrentShiftAuthsList: [],

      showOnlyWithScheduleError: false,
      showOnlyAssignedToUser: false,

      dayFrom: null,
      dayTo: null,

      found_employees: [],
      employee_id: undefined,

      resolveComment: undefined,

      acceptCommentVisible: false,
      denyReasonVisible: false,

      shiftAuthTypes: [],

      editingAssociateShiftId: undefined,
      associateShiftComponentKey: 0,
      visibleAssociateShiftModal: false,

      editingAssociateNumber: undefined,

      associateProfileModalVisible: false,

      total_shifts_with_auths: 0,

      associateProfileToSendSms: undefined,

      params: {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false
      },

      pagination: {
        pageSizeOptions: ['10', '50', '100'],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
      },
      columns: [
        {
          title: 'Shift ID',
          dataIndex: 'shift.id',
          key: 'shiftId',
          sorter: true
        },
        {
          title: 'Date',
          key: 'date',
          scopedSlots: {customRender: 'date'},
          sorter: true
        },
        {
          title: 'Associate',
          key: 'associate',
          scopedSlots: {customRender: 'associate'},
          sorter: true
        },
        {
          title: 'Actions',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
          align: 'center'
        }
      ],

      nestedColumns: [
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          className: 'column-wrap'
        },
        {
          title: 'Start',
          dataIndex: 'start',
          key: 'start',
        },
        {
          title: 'End',
          dataIndex: 'end',
          key: 'end',
        },
        {
          title: 'Work Hrs',
          dataIndex: 'work_hours'
        },
        {
          title: 'Pay Hrs',
          dataIndex: 'pay_hours'
        },
        {
          title: 'L1 Start',
          dataIndex: 'first_lunch_start',
          key: 'first_lunch_start',
        },
        {
          title: 'L1 End',
          dataIndex: 'first_lunch_end',
          key: 'first_lunch_end',
        },
        {
          title: 'L1 Dur',
          dataIndex: 'lunch_duration',
          key: 'lunch_duration'
        },
        {
          title: 'L2 Start',
          dataIndex: 'second_lunch_start',
          key: 'second_lunch_start',
        },
        {
          title: 'L2 End',
          dataIndex: 'second_lunch_end',
          key: 'second_lunch_end',
        },
        {
          title: 'L2 Dur',
          dataIndex: 'second_lunch_duration',
          key: 'second_lunch_duration'
        }
      ],

      filters: { ...filtersBaseState },
      filtersModalVisible: false,
      filtersModalKey: 0,

      callbacks: {
        onSkip: () => localStorage.setItem('authsTourPassed', true),
        onFinish: () => localStorage.setItem('authsTourPassed', true)
      }
    }
  },
  mixins: [api, shiftsHelper],
  watch: {
    dayFrom() {
      this.loadData()
    },
    dayTo() {
      this.loadData()
    },
    selectedAuthType() {
      this.loadData()
    },
    filters: {
      handler() {
        this.loadData();
      },
      deep: true
    }
  },
  computed: {
    user() {
      return this.$store.state.applicationState.user;
    },
    enableResetButton() {
      return JSON.stringify(filtersBaseState) != JSON.stringify(this.filters) || this.dayFrom || this.dayFrom;
    }
  },
  methods: {
    updateTotal(newTotal) {
      this.pagination = {...this.pagination, total: newTotal};
    },
    loadData(sorter) {
      if (this.loading) {
        return;
      }

      this.tableData = [];
      this.loading = true;

      this.apiGetShiftAuthsGroupedByShifts(
        this.projectId,
        this.pagination,
        this.$store.state.applicationState.currentPaymentType,
        this.filters.associateNumber,
        this.filters.withScheduleError,
        this.filters.assignedToMe,
        this.filters.authType,
        this.dayFrom,
        this.dayTo,
        sorter,
        true
      )
        .then((response) => {
          this.tableData = response.body.authorizations_by_shift;
          this.total_shifts_with_auths = response.body.total_shifts_with_auths;
          this.updateTotal(response.body.total_shifts_with_auths);
        })
        .finally(() => this.loading = false)
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination = {...pagination}
      this.loadData(sorter)
    },
    formatDateString(dateString) {
      return moment(dateString).format(this.workDateFormat);
    },

    acceptSelectedAuthList() {
      this.apiSetShiftAuthStatusForMultipleAuths(this.selectedCurrentShiftAuthsList, AuthStatus.ACCEPTED, this.resolveComment)
        .then((response) => {
          if (response.body.error_code != '0') {
            this.showNotification('error', 'Error', 'Authorization status were not set due to an error. Try again');
            this.cleanShiftAuthsEditModalData()
            return;
          }

          this.setShiftAuthStatusesLocally(AuthStatus.ACCEPTED);
          this.updateTotalAuths()

          this.showNotification('success', 'Success', 'Authorization statuses were set successfully');
        })
        .finally(() => this.cleanShiftAuthsEditModalData())
    },
    denySelectedAuthList() {
      this.apiSetShiftAuthStatusForMultipleAuths(this.selectedCurrentShiftAuthsList, AuthStatus.DECLINED, this.resolveComment, this.params)
        .then((response) => {
          if (response.body.error_code != '0') {
            this.showNotification('error', 'Error', 'Authorization statuses were not set due to an error. Try again');
            this.cleanShiftAuthsEditModalData();
            return;
          }

          this.setShiftAuthStatusesLocally(AuthStatus.DECLINED);

          this.showNotification('success', 'Success', 'Authorization statuses were set successfully');
        })
        .finally(() => this.cleanShiftAuthsEditModalData());
    },

    setShiftAuthStatusesLocally(status) {
      this.currentShiftRecord.authorizations.forEach(auth => {
        if (this.selectedCurrentShiftAuthsList.includes(auth.id)) {
          auth.status = status;
          auth.reviewed_by = {
            first_name: this.user.first_name,
            last_name: this.user.last_name
          };
          auth.review_date = moment();
        }
      })

      if (!this.currentShiftRecord.authorizations.some(auth => auth.status == AuthStatus.INITIAL)) {
        this.tableData = this.tableData.filter(item => item.shift.id !== this.currentShiftRecord.shift.id);
      }
    },

    updateTotalAuths() {
      this.total_auths -= this.selectedCurrentShiftAuthsList.length
      if (!this.currentShiftRecord.authorizations.some(auth =>
        auth.status == AuthStatus.INITIAL || auth.status == AuthStatus.DECLINED)) {
        this.total_shifts_with_auths -= 1;
      }
    },

    acceptManualShiftAuth(record) {
      this.currentShiftRecord = record;
      this.currentShiftAuthsList = record.authorizations;
      this.selectedCurrentShiftAuthsList = record.authorizations.map((auth) => auth.id);
      this.acceptCommentModalOpen();
    },
    declineManualShiftAuth(record) {
      this.currentShiftRecord = record;
      this.currentShiftAuthsList = record.authorizations;
      this.selectedCurrentShiftAuthsList = record.authorizations.map((auth) => auth.id);
      this.denyReasonSelectorOpen();
    },

    acceptCommentModalOpen() {
      this.acceptCommentVisible = true;
    },
    denyReasonSelectorOpen() {
      const fullSelectedAuths = this.currentShiftAuthsList.filter(auth =>
        this.selectedCurrentShiftAuthsList.includes(auth.id)
      );

      this.denyReasonVisible = true;
      this.$nextTick(() => {
        this.$refs.shiftAuthDenyForm.setAuths(fullSelectedAuths);
      });
    },
    setResolveComment(reason) {
      this.resolveComment = reason;
    },
    setDenyParams(params) {
      this.params = params;
    },
    confirmDeny() {
      this.denyReasonVisible = false;
      if (this.resolveComment && this.selectedCurrentShiftAuthsList && this.selectedCurrentShiftAuthsList.length > 0) {
        this.denySelectedAuthList();
      } else {
        notification['warning']({
          message: 'Error',
          description: 'Please fill deny reason and try again'
        })
      }
    },
    confirmAccept() {
      this.acceptCommentVisible = false;

      if (this.selectedCurrentShiftAuthsList && this.selectedCurrentShiftAuthsList.length > 0) {
        this.acceptSelectedAuthList();
      }
    },

    cleanShiftAuthsEditModalData() {
      this.currentShiftAuthsList = [];
      this.currentShiftRecord = undefined;
      this.selectedCurrentShiftAuthsList = [];
      this.resolveComment = "";
      this.params = {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false
      };
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    expandedRowRender(record) {
      const formattedDurations = this.formatShiftAuthDurations(record.shift);

      const data = [
        {
          key: record.id,
          type: 'Scheduled',
          date: formattedDurations.scheduled.date,
          start: formattedDurations.scheduled.start,
          end: formattedDurations.scheduled.end,
          first_lunch_start: '-',
          first_lunch_end: '-',
          second_lunch_start: '-',
          second_lunch_end: '-',
          duration: record.shift.scheduled_duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.scheduled_duration) : '-',
          work_hours: '-',
          pay_hours: '-',
          lunch_duration: '-',
          second_lunch_duration: '-'
        },
        {
          key: record.id,
          type: 'Actual',
          date: formattedDurations.actual.date,
          start: formattedDurations.actual.start,
          end: formattedDurations.actual.end,
          first_lunch_start: '-',
          first_lunch_end: '-',
          second_lunch_start: '-',
          second_lunch_end: '-',
          duration: record.shift.duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.scheduled_duration) : '-',
          work_hours: '-',
          pay_hours: '-',
          lunch_duration: '-',
          second_lunch_duration: '-'
        },
        {
          key: record.id,
          type: 'Signed',
          date: formattedDurations.signed.date,
          start: formattedDurations.signed.start,
          end: formattedDurations.signed.end,
          first_lunch_start: formattedDurations.signed.first_lunch_start,
          first_lunch_end: formattedDurations.signed.first_lunch_end,
          second_lunch_start: formattedDurations.signed.second_lunch_start,
          second_lunch_end: formattedDurations.signed.second_lunch_end,
          duration: record.shift.duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.duration) : '-',
          work_hours: this.formatWorkHours(
            record.shift.work_start ? moment.utc(record.shift.work_start) : null,
            record.shift.work_end ? moment.utc(record.shift.work_end) : null,
            record.shift.lunch_start ? moment.utc(record.shift.lunch_start) : null,
            record.shift.lunch_end ? moment.utc(record.shift.lunch_end) : null,
            record.shift.second_lunch_start ? moment.utc(record.shift.second_lunch_start) : null,
            record.shift.second_lunch_end ? moment.utc(record.shift.second_lunch_end) : null
          ),
          pay_hours: this.formatPayHours(
            record.shift.scheduled_start ? moment.utc(record.shift.scheduled_start) : null,
            record.shift.scheduled_end ? moment.utc(record.shift.scheduled_end) : null,
            record.shift.work_start ? moment.utc(record.shift.work_start) : null,
            record.shift.work_end ? moment.utc(record.shift.work_end) : null,
            record.shift.lunch_start ? moment.utc(record.shift.lunch_start) : null,
            record.shift.lunch_end ? moment.utc(record.shift.lunch_end) : null,
            record.shift.second_lunch_start ? moment.utc(record.shift.second_lunch_start) : null,
            record.shift.second_lunch_end ? moment.utc(record.shift.second_lunch_end) : null
          ),
          lunch_duration: record.shift.lunch_duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.lunch_duration) : '-',
          second_lunch_duration: record.shift.second_lunch_duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.second_lunch_duration) : '-',
        }
      ];

      return <a-table id="shift-table" size="small" columns={this.nestedColumns} dataSource={data} rowKey="key"
                      pagination={false}/>;
    },

    fetchUsers(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout)
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.apiSearchAssociate(value)
          .then((resp) => {
            this.found_employees = resp.data.associate_list.map(user => ({
              label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
              key: user.employee_id,
              value: user.employee_id,
            }))
          })
          .catch(err => console.error(err))
      }, 1000)
    },

    disabledStartDate(startValue) {
      const endValue = this.dayTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },

    disabledEndDate(endValue) {
      const startValue = this.dayFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    editShift(shiftId) {
      this.editingAssociateShiftId = shiftId;
      this.openAssociateShiftModal();
    },

    openAssociateShiftModal() {
      this.associateShiftComponentKey++;
      this.visibleAssociateShiftModal = true;
    },

    closeAssociateShiftModal() {
      this.visibleAssociateShiftModal = false;
      this.clearAssociateShiftModalData();

      this.associateShiftComponentKey++;
    },

    closeAssociateShiftModalWithChanges() {
      this.closeAssociateShiftModal();
      this.loadData();
    },

    openShiftDeletionConfirmationModal(shiftId) {
      this.deletingAssociateShiftId = shiftId;
      this.visibleAssociateShiftModal = false;
    },

    handleShiftExists(overlappingShifts) {
      this.visibleAssociateShiftModal = false;

      this.overlappingShifts = overlappingShifts;
    },

    endEditAssociateProfile() {
      this.editingAssociateNumber = undefined;
      this.associateProfileModalVisible = false;
    },

    handleEditedAssociateProfile() {
      this.endEditAssociateProfile();
      this.loadData();
    },

    startEditAssociateProfile(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      this.associateProfileModalVisible = true;
    },

    openSmsSendModal(associate) {
      this.associateProfileToSendSms = associate;
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },

    applyFilters(filters) {
      if (JSON.stringify(filters) == JSON.stringify(this.filters)) {
        return;
      }

      this.filters = {...filters};
      this.closeFilterModal();
    },

    showFilterModal() {
      this.filtersModalKey++;
      this.filtersModalVisible = true;
    },
    closeFilterModal() {
      this.filtersModalVisible = false;
    },

    resetFilters() {
      this.filters = {...filtersBaseState};

      this.dayFrom = undefined;
      this.dayTo = undefined;
    },
  },

  mounted() {
    this.loadData();

    const localPagination = localStorage.getItem('shiftAuthsPagination');
    this.pagination = localPagination ? JSON.parse(localPagination) : initialPagination;
  }
}
</script>

<style scoped>
.table_predefined_filters {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  flex-shrink: 1;
  align-items: center;
  justify-content: end;
}
.auth-tag {
  margin-top: 3px;
}
</style>
