<template>
  <div>
    <a-modal
        style="width: 600px"
        title="Reason of Deny Authorization"
        v-model="denyReasonVisible"
        @ok="confirmDeny"
    >
      <shift-auth-deny-form @reason="setDenyReason"/>
    </a-modal>
    <h2 class="view-header">Shift Authorizations</h2>
    <h3 v-if="shiftId">Shift {{ shiftId }}</h3>
    <h3 v-if="associateNumber">Associate {{ associateNumber }}</h3>

    <div class="table_controls_and_data">
      <div class="table_controls_wrapper">
        <a-button type="primary" @click="goBack">Back</a-button>
      </div>
    </div>
    <a-table
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        :pagination="pagination"
        :expanded-row-render="expandedRowRender"
        :style="{'whiteSpace': 'pre'}"
    >
      <span slot="actions" slot-scope="text, record">
            <a-button-group>
              <a-button title="Accept Authorization" icon="check" @click="accept(record.id)"/>
              <a-button title="Deny Authorization" icon="close" @click="startDeny(record.id)"/>
              <a-button title="Open Shift" icon="profile" @click="goToShift(record.shift_id)"/>
            </a-button-group>
        </span>
    </a-table>
  </div>
</template>

<script>

import {
  Col,
  Row,
  Button,
  Input,
  Icon,
  notification,
  DatePicker,
  Select,
  Pagination,
  Table,
  Modal
} from 'ant-design-vue';

import api from '@/api';
import Util from '@/util';
import moment from 'moment-timezone';
import store from '@/store';
import printJS from "print-js";

import shiftsHelper from "@/helpers/shifts";
import shiftAuths from "@/helpers/shift-auths";
import ShiftAuthDenyForm from "@/components/shift-auth-deny-form.vue";
import ShiftAttachmentsUpload from "@/components/shift-attachments-upload.vue";

const AuthStatus = {
  ACCEPT: 'accepted',
  REVOKE: 'declined'
};

export default {
  components: {
    ShiftAttachmentsUpload,
    ShiftAuthDenyForm,
    'a-date-picker': DatePicker,
    'a-row': Row,
    'a-col': Col,
    'a-input': Input,
    'a-button': Button,
    'a-modal': Modal,
    'a-button-group': Button.Group,
    'a-select': Select,
    'a-pagination': Pagination,
    'a-table': Table,
    Button,
    Icon
  },

  mixins: [api, shiftsHelper],

  computed: {
    dateFromString() {
      return this.dateFrom ? moment.utc(this.dateFrom).format('YYYY-MM-DD') : null
    },
    dateToString() {
      return this.dateTo ? moment.utc(this.dateTo).format('YYYY-MM-DD') : null
    },
  },

  data() {
    return {
      loading: false,
      searching_users: false,

      inputComment: null,
      denyReasonVisible: false,
      idToDeny: null,
      denyReason: null,

      dateFrom: null,
      dateTo: null,
      userSearchInput: null,
      selected_employees: [],
      found_employees: [],

      tableData: [],
      printData: [],

      shiftId: null,
      associateNumber: null,

      serverDateFormat: store.state.applicationState.serverDateFormat,

      pagination: {
        pageSizeOptions: ['10', '50', '100'],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
        onChange: (page) => {
          this.pagination.current = page;
          console.log('onChange')
          this.loadData()
        },
        onShowSizeChange: (current, size) => {
          this.pagination.pageSize = size;
          console.log('onShowSizeChange')
          this.loadData();
        }
      },

      windowWidth: window.innerWidth,

      workDateFormat: store.state.applicationState.dateTimeFormat,

      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Associate',
          dataIndex: 'associate_name',
          key: 'associate_name',
          className: 'column-wrap',
        },
        {
          title: 'Action',
          dataIndex: 'info',
          key: 'info',
        },
        {
          title: 'Created On',
          dataIndex: 'created_date',
          key: 'created_date'
        },
        {
          title: 'Authorized By',
          dataIndex: 'provider',
          key: 'provider'
        },
        {
          title: 'Details',
          key: 'details',
          dataIndex: 'details',
          className: 'column-wrap'
        },
        {
          title: 'Actions',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
          align: 'center',
        }
      ],

      nestedColumns: [
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          className: 'column-wrap'
        },
        {
          title: 'Start',
          dataIndex: 'start',
          key: 'start',
        },
        {
          title: 'End',
          dataIndex: 'end',
          key: 'end',
        },
        {
          title: 'Duration',
          dataIndex: 'duration',
          key: 'duration'
        },
        {
          title: '1st Lunch Start',
          dataIndex: 'first_lunch_start',
          key: 'first_lunch_start',
        },
        {
          title: '1st Lunch End',
          dataIndex: 'first_lunch_end',
          key: 'first_lunch_end',
        },
        {
          title: '1st Lunch Duration',
          dataIndex: 'lunch_duration',
          key: 'lunch_duration'
        },
        {
          title: '2nd Lunch Start',
          dataIndex: 'second_lunch_start',
          key: 'second_lunch_start',
        },
        {
          title: '2nd Lunch End',
          dataIndex: 'second_lunch_end',
          key: 'second_lunch_end',
        },
        {
          title: '2nd Lunch Duration',
          dataIndex: 'second_lunch_duration',
          key: 'second_lunch_duration'
        }
      ]
    }
  },
  methods: {
    convertType: shiftAuths.convertType,
    async onKeydown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault();
        try {
          await this.loadPrintData()
          this.$nextTick(() => printJS({
            printable: this.printData,
            properties: [
              {field: 'id', displayName: 'ID'},
              {field: 'associate_name', displayName: 'Associate Name'},
              {field: 'info', displayName: 'Info'},
              {field: 'provider', displayName: 'Auth Provider Name'},
              {field: 'shift_duration', displayName: 'Shift Duration'}
            ],
            type: 'json',
            header: '<h4>Shift Authorizations</h4>',
            style: 'tr { page-break-inside: avoid; page-break-after: auto; }',
            documentTitle: ' '
          }));
        } catch (error) {
          console.error(error)
        }
      }
    },
    getShiftDurationInfoFromItem(item) {
      const shift = item.shift;
      let scheduled, actual;
      if (
          shift.scheduled_start != null && shift.scheduled_start.length > 0
          && shift.scheduled_end != null && shift.scheduled_end.length > 0
      ) {
        scheduled = 'Scheduled: '
            + Util.convertDateTimeToCurrentTimeZoneWithFormat(shift.scheduled_start, this.workDateFormat)
            + ' - '
            + Util.convertDateTimeToCurrentTimeZoneWithFormat(shift.scheduled_end, this.workDateFormat)
            + ' (' + Util.getFormattedDuration(moment(shift.scheduled_start), moment(shift.scheduled_end)) + ')'
      }

      if (shift.start != null && shift.start.length > 0
          && shift.end != null && shift.end.length > 0) {
        actual = 'Actual: '
            + Util.convertDateTimeToCurrentTimeZoneWithFormat(shift.start, this.workDateFormat)
            + ' - '
            + Util.convertDateTimeToCurrentTimeZoneWithFormat(shift.end, this.workDateFormat)
            + ' (' + Util.getFormattedDuration(moment(shift.start), moment(shift.end)) + ')'
      }

      switch (true) {
        case scheduled != null && actual != null:
          return scheduled + '\n' + actual;
        case scheduled != null && actual == null:
          return scheduled;
        case scheduled == null && actual != null:
          return actual;
      }

      return '-';
    },

    loadData() {
      this.tableData = [];
      this.loading = true;

      this.apiGetShiftAuths(this.selected_employees, this.dateFromString, this.dateToString, this.pagination, this.shiftId)
          .then((data) => {
            this.pagination.total = data.body.total;

            if (!data.body.shift_auth_items || data.body.shift_auth_items.length == 0) {
              notification['info']({
                message: 'No unresolved authorizations',
                description: 'You will be redirected to the previous page'
              })

              setTimeout(() => this.goBack(), 2000);
            }

            data.body.shift_auth_items.forEach(item => {
              const formattedDurations = this.formatShiftDurations(item.shift);

              this.tableData.push({
                key: item.id + Math.random(),
                "id": item.id,
                "shift_id": item.shift.id,
                "created_date": moment(item.created_date).format(this.workDateFormat),
                "associate_name": Util.combineAssociateName(item.associate.first_name, item.associate.last_name),
                "info": this.convertType(item.type),
                "details": item.details && item.details.length > 0 ? item.details : '-',
                "provider": item.provider,

                "scheduled_date": formattedDurations.scheduled.date,
                "actual_date": formattedDurations.actual.date,
                "signed_date": formattedDurations.signed.date,

                "scheduled_start": formattedDurations.scheduled.start,
                "scheduled_end": formattedDurations.scheduled.end,
                "actual_start": formattedDurations.actual.start,
                "actual_end": formattedDurations.actual.end,
                "signed_start": formattedDurations.signed.start,
                "signed_end": formattedDurations.signed.end,

                "scheduled_duration": item.shift.scheduled_duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(item.shift.scheduled_duration) : '-',
                "duration": item.shift.duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(item.shift.actual_duration) : '-',
                "signed_duration": item.shift.signed_duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(item.shift.duration) : '-',

                "actual_first_lunch_start": formattedDurations.actual.first_lunch_start,
                "actual_first_lunch_end": formattedDurations.actual.first_lunch_end,
                "signed_first_lunch_start": formattedDurations.signed.first_lunch_start,
                "signed_first_lunch_end": formattedDurations.signed.first_lunch_end,

                "first_lunch_duration": item.shift.actual_lunch_duration ? Util.formatMinutesToHoursAndMinutesDuration(item.shift.actual_lunch_duration) : '-',
                "signed_first_lunch_duration": item.shift.lunch_duration ? Util.formatMinutesToHoursAndMinutesDuration(item.shift.lunch_duration) : '-',

                "actual_second_lunch_start": formattedDurations.actual.second_lunch_start,
                "actual_second_lunch_end": formattedDurations.actual.second_lunch_end,
                "signed_second_lunch_start": formattedDurations.signed.second_lunch_start,
                "signed_second_lunch_end": formattedDurations.signed.second_lunch_end,

                "second_lunch_duration": item.shift.actual_second_lunch_duration ? Util.formatMinutesToHoursAndMinutesDuration(item.shift.actual_second_lunch_duration) : '-',
                "signed_second_lunch_duration": item.shift.second_lunch_duration ? Util.formatMinutesToHoursAndMinutesDuration(item.shift.second_lunch_duration) : '-'
              })
            })
          })
          .catch(error => console.error(error))
          .finally(() => this.loading = false);
    },
    async loadPrintData() {
      this.printData = [];
      this.loading = true;
      await this.apiGetShiftAuths(this.selected_employees, this.dateFromString, this.dateToString, null, this.shiftId, this.associateNumber)
          .then((data) => {
            data.body.shift_auth_items.forEach(item => this.printData.push({
              "id": item.id,
              "associate_name": Util.combineAssociateName(item.associate.first_name, item.associate.last_name),
              "info": this.convertType(item.type),
              "provider": item.provider,
              "shift_duration": this.getShiftDurationInfoFromItem(item)
            }))
          })
          .catch(error => console.error(error))
          .finally(() => this.loading = false);
    },

    fetchUsers(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout)
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.searching_users = true
        this.apiSearchAssociate(value).then((resp) => {
          this.found_employees = resp.data.associate_list.map(user => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id
          }))
          this.searching_users = false
        })
      }, 1000)
    },

    handleSearchUsersChange(value) {
      this.selected_employees = value;
      this.searching_users = false;
      this.$refs.userSelect.blur();
    },

    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    expandedRowRender(record) {
      const data = [
        {
          key: 'scheduled',
          type: 'Scheduled',
          date: record.scheduled_date,
          start: record.scheduled_start,
          end: record.scheduled_end,
          first_lunch_start: '-',
          first_lunch_end: '-',
          second_lunch_start: '-',
          second_lunch_end: '-',
          duration: record.scheduled_duration,
          lunch_duration: '-',
          second_lunch_duration: '-'
        },
        {
          key: 'actual',
          type: 'Actual',
          date: record.actual_date,
          start: record.actual_start,
          end: record.actual_end,
          first_lunch_start: record.actual_first_lunch_start,
          first_lunch_end: record.actual_first_lunch_end,
          second_lunch_start: record.actual_second_lunch_start,
          second_lunch_end: record.actual_second_lunch_end,
          duration: record.duration,
          lunch_duration: record.first_lunch_duration,
          second_lunch_duration: record.second_lunch_duration
        },
        {
          key: 'signed',
          type: 'Signed',
          date: record.signed_date,
          start: record.signed_start,
          end: record.signed_end,
          first_lunch_start: record.signed_first_lunch_start,
          first_lunch_end: record.signed_first_lunch_end,
          second_lunch_start: record.signed_second_lunch_start,
          second_lunch_end: record.signed_second_lunch_end,
          duration: record.signed_duration,
          lunch_duration: record.signed_first_lunch_duration,
          second_lunch_duration: record.signed_second_lunch_duration
        }
      ];

      return <a-table columns={this.nestedColumns} dataSource={data} pagination={false}/>;
    },

    goToShift(id) {
      this.$router.push({path: '/time-card', query: {id}});
    },

    accept(authId) {
      this.acceptShiftAuth(authId)
    },

    startDeny(id) {
      this.idToDeny = id;
      this.denyReasonVisible = true;
    },
    setDenyReason(reason) {
      this.denyReason = reason;
    },
    confirmDeny() {
      this.denyReasonVisible = false;
      if (this.denyReason && this.idToDeny) {
        this.denyShiftAuth(this.idToDeny, this.denyReason);
      }
    },

    acceptShiftAuth(authId) {
      if (authId === null) {
        return;
      }
      this.apiSetShiftAuthStatus(authId, AuthStatus.ACCEPT, null)
          .then((data) => {
            console.log('setShiftAuthStatus')
            this.loadData();

            notification['success']({
              message: 'Info',
              description: 'Shift authorization has been accepted.'
            })
          })
          .catch(error => console.error(error))
    },
    denyShiftAuth(authId, comment) {
      this.apiSetShiftAuthStatus(authId, AuthStatus.REVOKE, comment)
          .then((data) => {
            console.log('denyShiftAuth')
            this.loadData();

            notification['success']({
              message: 'Info',
              description: 'Shift authorization has been denied.'
            })
          })
          .catch(error => console.error(error))
    },

    goBack() {
      this.$router.back()
    },
  },

  mounted() {
    const query = this.$route.query;

    if (query) {
      if (query.shiftId) {
        this.shiftId = query.shiftId;
      }

      if (query.associateNumber) {
        this.associateNumber = query.associateNumber;
        this.selected_employees = [query.associateNumber];
      }
    }

    this.loadData();
  }
};
</script>

<style>
.modal {
  width: 500px;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
}

.fadeIn-enter .modal,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
}

.column-wrap {
  word-wrap: break-word;
  white-space: normal;
}
</style>
