<template>
  <div>
    <generate-payroll-for-range-modal :visible="reportForRangeModalVisible" @cancel="closeReportForRange"/>
    <h2 class="view-header">Payrolls</h2>
    <div>
      <div class="table_controls_and_data">
        <div class="table_data_wrapper"></div>
        <div class="table_controls_wrapper">
          <div class="table_predefined_filters">
            <a-button type="primary" @click="addPayroll">Add</a-button>
            <a-button @click="openReportForRange">Report for Range</a-button>
          </div>
        </div>
      </div>
      <a-table
          size="small"
          :columns="columns"
          :data-source="tableData"
          :loading="loading"
          :pagination="pagination"
          row-key="id"
      >
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-dropdown :disabled="loadingReport">
            <a-menu slot="overlay">
              <a-menu-item key="2" @click="downloadPayrollReport(record.id, 'csv')">
                <a-icon type="file" />
                CSV
              </a-menu-item>
              <a-menu-item key="1" @click="downloadPayrollReport(record.id, 'excel')">
                <a-icon type="file-excel" />
                Excel
              </a-menu-item>
            </a-menu>
            <a-button size="small">
              Download <a-icon type="down" />
            </a-button>
          </a-dropdown>
          <a-button icon="edit" @click="editPayroll(record.id)"/>
          <a-button icon="delete" @click="deletePayroll(record.id, record.name)"/>
        </a-button-group>
      </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import antd, {Table, Button, Modal, Select, DatePicker, notification, Dropdown, Menu, Icon} from "ant-design-vue";
import Util from "@/util";
import store from "@/store";
import GeneratePayrollForRangeModal from "@/components/generate-payroll-for-range-modal.vue";

export default {
  components: {
    'a-table': Table,
    'a-button': Button,
    'a-button-group': Button.Group,
    'a-modal': Modal,
    'a-date-picker': DatePicker,
    'a-select': Select,
    'a-dropdown': Dropdown,
    'a-menu': Menu,
    'a-menu-item': Menu.Item,
    'a-icon': Icon,
    'generate-payroll-for-range-modal': GeneratePayrollForRangeModal,
  },
  mixins: [api, Util],
  data() {
    return {
      dateFrom: undefined,
      dateTo: undefined,

      loading: false,
      loadingReport: false,
      reportForRangeModalVisible: false,

      tableData: [],

      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '50'],
        pageSize: 50
      },

      tableDateFormat: store.state.applicationState.dateFormat,

      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          align: 'center'
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align: 'center'
        },
        {
          title: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
          align: 'center'
        },
        {
          title: 'End Date',
          dataIndex: 'endDate',
          key: 'endDate',
          align: 'center'
        },
        {
          title: 'Actions',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
          align: 'center'
        }
      ],
    }
  },
  methods: {
    async loadData() {
      this.tableData = [];
      this.loading = true;
      try {
        const response = await this.apiGetAllPayrolls();

        if (!response.data || response.data.error_code != 0) {
          throw new Error();
        }

        response.data.payrolls.forEach(payroll => {
          this.tableData.push({
            id: payroll.id,
            name: payroll.name,
            startDate: Util.convertDateTimeToCurrentTimeZoneWithFormat(payroll.start_date, this.tableDateFormat),
            endDate: Util.convertDateTimeToCurrentTimeZoneWithFormat(payroll.end_date, this.tableDateFormat)
          });
        });

        this.loading = false;
      } catch (error) {
        this.showNotification('error', 'Error', "Error occurred while receiving payrolls");
        this.loading = false;
      }
    },

    addPayroll() {
      this.$router.push({path: '/payroll', query: {id: 'new'}});
    },
    editPayroll(id) {
      this.$router.push({path: '/payroll', query: {id}});
    },
    deletePayroll(id, name) {
      antd.Modal.confirm({
        title: 'Are you sure?',
        content: `You are about to delete the payroll "${name}".
        If you delete it, you will also delete the documents attached to it. Continue?`,
        onOk: async () => {
          const response = await this.apiDeletePayroll(id);

          if (response.data.error_code == 0) {
            this.showNotification('success', 'Success', `Payroll "${name}" has been successfully deleted`)
          } else {
            this.showNotification('error', 'Error', 'An error occurred while deleting payroll');
          }

          await this.loadData();
        },
        onCancel() {}
      })
    },

    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },
    downloadPayrollReport(payrollId, reportType) {
      this.loadingReport = true;
      this.apiDownloadPayrollReport(payrollId, reportType)
        .then((response) => {
          if (response.body.report_url) {
            window.open(response.body.report_url, '_blank');
          } else {
            this.showNotification(
              'warning',
              response.body.msg ?? `Failed to download ${reportType.toUpperCase()} report for payroll #${payrollId}`,
              'Please try again later'
            )
          }
        })
        .catch((resp) => {
          this.showNotification(
            'warning',
            `Failed to download ${reportType.toUpperCase()} report for payroll #${payrollId}`,
            'Please try again later'
          )
        })
        .finally(() => this.loadingReport = false);
    },
    openReportForRange() {
      this.reportForRangeModalVisible = true;
    },
    closeReportForRange() {
      this.reportForRangeModalVisible = false;
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>
