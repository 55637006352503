import Vue from 'vue';
import VueRouter from 'vue-router';
import TimeCards from '../views/associate-shifts.vue';
import TimeCard from '../views/associate-shift-old.vue';
import PayCodes from '../views/pay-codes.vue';
import ShiftAuth from '../views/shift-authorization.vue';
import Shifts from '../views/shifts.vue';
import EmplRep from '@/views/associate-report.vue';
import Documents from "@/views/documents.vue";
import PushNotifications from "@/views/push-notifications.vue";
import store from "@/store";
import Project from "@/views/project.vue";
import OvertimeReport from "@/views/overtime-report.vue";
import TotalHoursDetailReport from "@/views/total-hours-detail-report.vue";
import MispunchReport from "@/views/mispunch-report.vue";
import AssociatePayCode from "@/views/associate-pay-code.vue";
import AssociatePayCodes from "@/views/associate-pay-codes.vue";
import Payrolls from "@/views/payrolls.vue";
import Payroll from "@/views/payroll.vue";
import ProjectShiftAuths from "@/views/project-shift-auths.vue";
import AssociateProfiles from "@/views/associate-profiles.vue";
import CreateUserWithAssociate from "@/views/create-user-with-associate.vue";
import PbjReport from "@/views/pbj-report.vue";
import SignedShiftsReport from "@/views/signed-shifts-report.vue";
import GeneralDailyReport from "@/views/daily-summary.vue";
import AssociateOverview from "@/views/associate-activity.vue";
import AlertStore from "@/views/alert-store.vue";
import TimeOffRequests from "@/views/time-off-requests.vue";
import ManualShiftAuths from "@/views/manual-shift-auths.vue";
import Auth from "@/views/auth.vue";
import Dev from "@/views/dev.vue";
import WorkedShiftsReport from "@/views/worked-shifts-report.vue";
import PayrollSystemIdReport from "@/views/payroll-system-id-report.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Project,
    props: true,
    meta: { title: 'Company - ePeople Time' }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    props: true,
    meta: { title: 'ePeople Time' }
  },
  {
    path: '/shifts',
    name: 'shifts',
    component: TimeCards,
    meta: { title: 'Associate Shifts - ePeople Time' }
  },
  {
    path: '/time-card',
    name: 'time-card',
    component: TimeCard,
    props: true,
    meta: { title: 'Shift - ePeople Time' }
  },
  {
    path: '/associate-pay-code',
    name: 'associate-pay-code',
    component: AssociatePayCode,
    meta: { title: 'Associate Pay Code - ePeople Time' }
  },
  {
    path: '/associate-pay-codes',
    name: 'associate-pay-codes',
    component: AssociatePayCodes,
    meta: { title: 'Associate Pay Codes - ePeople Time' }
  },
  {
    path: '/pay-codes',
    name: 'pay-codes',
    component: PayCodes,
    props: true,
    meta: { title: 'Pay Codes - ePeople Time' }
  },
  {
    path: '/shift-authorization',
    name: 'shift-authorization',
    component: ShiftAuth,
    props: true,
    meta: { title: 'Shift Authorization - ePeople Time' }
  },
  {
    path: '/shifts-dictionary',
    name: 'shifts-dictionary',
    component: Shifts,
    props: true,
    meta: { title: 'Shifts Dictionary - ePeople Time' }
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('../views/payments.vue'),
    meta: { title: 'Payments - ePeople Time' }
  },
  {
    path: '/payment-info',
    name: 'payment-info',
    component: () => import('../views/payment-info.vue'),
    props: true,
    meta: { title: 'Payment Info - ePeople Time' }
  },
  // {
  //   path: '/attendance-report',
  //   name: 'attendance-report',
  //   component: () => import('../views/attendance-report'),
  //   props: true,
  //   meta: { title: 'Attendance Report - ePeople Time' }
  // },
  {
    path: '/associate-report',
    name: 'associate-report',
    component: EmplRep,
    props: true,
    meta: { title: 'Associate Report - ePeople Time' }
  },
  {
    path: '/overtime-report',
    name: 'overtime-report',
    component: OvertimeReport,
    props: true,
    meta: { title: 'Overtime Report - ePeople Time' }
  },
  {
    path: '/total-hours-detail-report',
    name: 'total-hours-detail-report',
    component: TotalHoursDetailReport,
    props: true,
    meta: { title: 'Total Hours Detail - ePeople Time' }
  },
  {
    path: '/mispunch-report',
    name: 'mispunch-report',
    component: MispunchReport,
    props: true,
    meta: {title: 'Mispunch Report - ePeople Time'}
  },
  {
    path: '/signed-shifts-report',
    name: 'signed-shifts-report',
    component: SignedShiftsReport,
    props: true,
    meta: {title: 'Signed Shifts Report - ePeople Time'}
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('../views/roles.vue'),
    props: true,
    meta: {title: 'Job Classifications Dictionary - ePeople Time'}
  },
  {
    path: '/agencies',
    name: 'agencies',
    component: () => import('../views/agencies.vue'),
    props: true,
    meta: {title: 'Agencies Dictionary - ePeople Time'}
  },
  {
    path: '/not-in-work-area',
    name: 'not-in-work-area',
    component: () => import('../views/associate-not-in-wa.vue'),
    props: true,
    meta: {title: 'Not in Work Area Report - ePeople Time'}
  },
  {
    path: '/associate-questionary',
    name: 'associate-questionary',
    component: () => import('../views/questionary.vue'),
    props: true,
    meta: { title: 'Associate Questionary Report - ePeople Time' }
  },
  {
    path: '/active-associates',
    name: 'active-associates',
    component: () => import('../views/active-associates.vue'),
    props: true,
    meta: { title: 'Active Associates - ePeople Time' }
  },
  {
    path: '/time-attendance-detail-report',
    name: 'time-attendance-detail-report',
    component: () => import('../views/time-attendance-detail-report.vue'),
    props: true,
    meta: { title: 'Time Attendance Detail Report - ePeople Time' }
  },
  {
    path: '/pbj-report',
    name: 'pbj-report',
    component: PbjReport,
    props: true,
    meta: { title: 'PBJ Report - ePeople Time' }
  },
  {
    path: '/worked-shifts-report',
    name: 'worked-shifts-report',
    component: WorkedShiftsReport,
    props: true,
    meta: { title: 'Worked Shifts Report - ePeople Time' }
  },
  {
    path: '/payroll-system-id-report',
    name: 'payroll-system-id-report',
    component: PayrollSystemIdReport,
    props: true,
    meta: { title: 'Payroll System ID Report - ePeople Time' }
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents,
    props: true,
    meta: { title: 'Documents Upload - ePeople Time' }
  },
  {
    path: '/push-notifications',
    name: 'push-notifications',
    component: PushNotifications,
    props: true,
    meta: { title: 'Notifications - ePeople Time' }
  },
  {
    path: '/payrolls',
    name: 'payrolls',
    component: Payrolls,
    props: true,
    meta: { title: 'Payrolls - ePeople Time' }
  },
  {
    path: '/payroll',
    name: 'payroll',
    component: Payroll,
    props: true,
    meta: { title: 'Payroll - ePeople Time' }
  },
  {
    path: '/project-shift-auths',
    name: 'project-shift-auths',
    component: ProjectShiftAuths,
    props: true,
    meta: { title: 'Shift Authorizations - ePeople Time' }
  },
  {
    path: '/manual-shift-auths',
    name: 'manual-shift-auths',
    component: ManualShiftAuths,
    props: true,
    meta: { title: 'Manual Shift Authorizations - ePeople Time' }
  },
  {
    path: '/associate-profiles',
    name: 'associate-profiles',
    component: AssociateProfiles,
    props: true,
    meta: { title: 'Associate Profiles - ePeople Time' }
  },
  {
    path: '/create-user-with-associate',
    name: 'create-user-with-associate',
    component: CreateUserWithAssociate,
    props: true,
    meta: { title: 'Create User With Associate - ePeople Time' }
  },
  {
    path: '/daily-summary',
    name: 'daily-summary',
    component: GeneralDailyReport,
    props: true,
    meta: { title: 'Daily Summary - ePeople Time' }
  },
  {
    path: '/activity',
    name: 'activity',
    component: AssociateOverview,
    props: true,
    meta: { title: 'Associate Activity - ePeople Time' }
  },
  {
    path: '/time-off',
    name: 'time-off',
    component: TimeOffRequests,
    props: true,
    meta: { title: 'Time Off Requests - ePeople Time' }
  },
  {
    path: '/alert-store',
    name: 'alert-store',
    component: AlertStore,
    props: true,
    meta: { title: 'Alert Store - ePeople Time' }
  },

  {
    path: '/dev',
    name: 'dev',
    component: Dev,
    props: true,
    meta: { title: 'Dev - ePeople Time' }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( async (to, from, next) => {
  if (to.path == '/auth') {
    if (!store.state.applicationState.authError) {
      next('/');
    }
    next();
  }

  try {
    document.title = to.meta != null && to.meta.title != null ? to.meta.title : 'ePeople Time';
    await store.dispatch('loadApplicationState');

    if (store.state.applicationState.authError && from.path != '/auth') {
      return next('/auth');
    }
    next();
  } catch (exception) {
    next('/auth');
  }
})

export default router
