<template>
  <div class="role-controls">
    <div
      class="content-header"
      style="font-size: 22px"
      v-if="editingRole"
    >
      Job classification #{{ editingRole.id }}
    </div>

    <div class="role-control-groups">
      <a-space
        direction="vertical"
        class="role-control-group"
      >
        <p
          class="content-subheader"
          style="text-align: center; font-size: 18px;"
        >
          Info
        </p>

        <div class="role-field">
          <p class="role-field-name">Name</p>
          <a-input
            v-model="role.name"
            class="role-field-value"
            placeholder="Name"
          />
        </div>
        <div class="role-field">
          <p class="role-field-name">Code</p>
          <a-input
            v-model="role.code"
            class="role-field-value"
            placeholder="Code"
          />
        </div>
      </a-space>
      <a-space
        direction="vertical"
        class="role-control-group"
        style="width: 60%"
      >
        <p
          class="content-subheader"
          style="text-align: center; font-size: 18px;"
        >
          Properties
        </p>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Manage Associates</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.MANAGE_ASSOCIATES }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[0].value"
          />

        </div>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Resolve Alerts</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.RESOLVE_ALERTS }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[1].value"
          />
        </div>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Manage Authorizations</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.MANAGE_AUTHORIZATIONS }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[2].value"
          />
        </div>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Manage Payment</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.MANAGE_PAYMENTS }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[3].value"
          />
        </div>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Manage Documents</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.MANAGE_DOCUMENTS }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[4].value"
          />
        </div>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Manage Company</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.MANAGE_PROJECT }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[5].value"
          />
        </div>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Floating Work Area</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.FLOATING_WORK_AREA }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[6].value"
          />
        </div>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Send Notification</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.SEND_NOTIFICATION }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[7].value"
          />
        </div>

        <div class="role-field">
          <div class="role-property-field">
            <p class="role-field-name">Invite User</p>
            <p class="role-property-description">{{ rolePropertiesDescriptionEnum.INVITE_USER }}</p>
          </div>
          <a-switch
            v-model="role.role_properties[8].value"
          />
        </div>
      </a-space>
    </div>

    <div class="view-role-buttons">
      <a-button type="primary" @click="saveRole">Save</a-button>
      <a-button type="danger" @click="closeRoleMenu">Cancel</a-button>
    </div>
  </div>

</template>

<style scoped>
.role-controls {
  padding: 10px 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.role-control-groups {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 2%;
}

.role-control-group {
  width: 35%
}

.view-role-buttons {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.role-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.role-property-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
//margin-bottom: 5%;
}

.role-field-name {
  padding: 0;
  margin: 0;
}

.role-field-value {
  max-width: 60%;
}

.role-property-description {
  padding: 0;
  margin: 0;
  font-size: 80%;
  text-align: start;
  width: 100%;
}

</style>

<script>

import api from "@/api";
import {Button, Space, Input, Switch, notification} from "ant-design-vue";

export default {
  components: {
    'a-button': Button,
    'a-space': Space,
    'a-input': Input,
    'a-switch': Switch,
  },

  mixins: [api],
  props: ['editingRole'],
  data: () => ({
    role: {
      id: null,
      name: '',
      code: '',
      description: '',
      role_properties: [
        {
          name: 'manage_associates',
          value: false
        },
        {
          name: 'resolve_alerts',
          value: false
        },
        {
          name: 'manage_authorizations',
          value: false
        },
        {
          name: 'manage_payments',
          value: false
        },
        {
          name: 'manage_documents',
          value: false
        },
        {
          name: 'manage_project',
          value: false
        },
        {
          name: 'floating_work_area',
          value: false
        },
        {
          name: 'send_notification',
          value: false
        },
        {
          name: 'invite_user',
          value: false
        },
      ]
    },

    rolePropertiesEnum: Object.freeze({
      MANAGE_ASSOCIATES: 'manage_associates',
      RESOLVE_ALERTS: 'resolve_alerts',
      MANAGE_AUTHORIZATIONS: 'manage_authorizations',
      MANAGE_PAYMENTS: 'manage_payments',
      MANAGE_DOCUMENTS: 'manage_documents',
      MANAGE_PROJECT: 'manage_project',
      FLOATING_WORK_AREA: 'floating_work_area',
      SEND_NOTIFICATION: 'send_notification',
      INVITE_USER: 'invite_user',
    }),

    rolePropertiesDescriptionEnum: Object.freeze({
      MANAGE_ASSOCIATES: 'Can view / edit associates\' profiles.',
      RESOLVE_ALERTS: 'Can view / resolve any company\'s alerts.',
      MANAGE_AUTHORIZATIONS: 'Can review shift authorizations from associates and be the provider for authorizations.',
      MANAGE_PAYMENTS: 'Can manage payment systems and accept / deny associates\' payment requests.',
      MANAGE_DOCUMENTS: 'Can view / edit / add / send to sign documents. ' +
        'Can manage Onboarding / Departure process for associates.',
      MANAGE_PROJECT: 'Can edit company settings and information.',
      FLOATING_WORK_AREA: 'Can work from any location.',
      SEND_NOTIFICATION: 'Can send notifications to associates.',
      INVITE_USER: 'Can invite users to company.',
    })

  }),

  methods: {
    saveRole() {
      if (!this.role.name) {
        notification['error']({
          message: 'Error',
          description: 'Job classification name cannot be empty!'
        })
        return
      }

      if (this.editingRole) {
        this.apiUpdateRole(this.role.id, this.role.name, this.role.code, this.role.role_properties)
          .then((response) => {
            if (response.data.error_code == 0) {
              notification['success']({
                message: 'Info',
                description: 'Job classification has been edited successfully'
              })
              this.$emit('on-add');
            } else {
              notification['error']({
                message: 'Error',
                description: 'An error occurred while editing the job classification. Please contact the administrator'
              })
            }
          })
          .catch(error => {
            notification['error']({
              message: 'Error',
              description: 'An error occurred while editing the job classification. Please contact the administrator'
            })
          })
        return
      }

      this.apiAddNotAdmRole(this.role.name, this.role.code, this.role.role_properties)
        .then((response) => {
          if (response.data.error_code == 0) {
            notification['success']({
              message: 'Info',
              description: 'Job classification has been created successfully'
            })
            this.$emit('on-add');
          } else {
            notification['error']({
              message: 'Error',
              description: 'An error occurred while saving the job classification. Please contact the administrator'
            })
          }
        })
        .catch(error => {
          notification['error']({
            message: 'Error',
            description: 'An error occurred while saving the job classification. Please contact the administrator'
          })
        })
    },

    closeRoleMenu() {
      this.$emit('on-cancel')
    },
  },

  mounted() {
    if (this.editingRole) {
      this.role = {...this.editingRole}
      const desiredOrder = Object.values(this.rolePropertiesEnum)
      this.role.role_properties.sort((a, b) => {
        const indexA = desiredOrder.indexOf(a.name);
        const indexB = desiredOrder.indexOf(b.name);
        return indexA - indexB;
      })
    }
  },
}

</script>
