<template>
  <div>
    <a-modal
      class="duplicate-shift-modal"
      v-model="visibleDuplicateShiftModal"
      title="Duplicate Shift"
      @cancel="cancelDuplicate"
    >
      <div v-if="shiftToDuplicate" class="flex flex-column gap-3">
        <div>
          You can duplicate a created shift over a span of dates or a single date.
        </div>
        <div class="flex flex-column gap-1">
          <div>
            Duration: {{ getSignedDuration(shiftToDuplicate) }}
          </div>
          <div v-if="shiftToDuplicate.lunch_start">
            First Lunch: {{ getFirstLunchDuration(shiftToDuplicate) ?? '-' }}
          </div>
          <div v-if="shiftToDuplicate.second_lunch_start">
            Second Lunch: {{ getSecondLunchDuration(shiftToDuplicate) ?? '-' }}
          </div>
        </div>
        <a-radio-group v-model="duplicateType">
          <a-radio-button value="span">
            Span
          </a-radio-button>
          <a-radio-button value="single">
            Single
          </a-radio-button>
        </a-radio-group>

        <div v-if="spanDuplicate" class="flex flex-row gap-2">
          <a-date-picker
            placeholder="Date From"
            v-model="spanDuplicateDateFrom"
            :disabled-date="disabledDateFrom"
            :format="visualDateFormat"
          />
          <a-date-picker
            placeholder="Date To"
            v-model="spanDuplicateDateTo"
            :disabled-date="disabledDateTo"
            :format="visualDateFormat"
          />
        </div>
        <div v-else-if="singleDuplicate">
          <a-date-picker
            placeholder="Date"
            v-model="singleDuplicateDate"
            :disabled-date="disabledDate"
            :format="visualDateFormat"
          />
        </div>
      </div>

      <template slot="footer">
        <div class="flex justify-content-end">
          <a-button
            type="primary"
            @click="confirmDuplicate"
          >
            Duplicate Shift
          </a-button>
          <a-button
            type="danger"
            @click="cancelDuplicate"
          >
            Cancel
          </a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      v-model="visibleAssociateShiftModal"
      width="950px"
      :footer="null"
      @cancel="closeAssociateShiftModal"
    >
      <associate-shift
        :key="associateShiftComponentKey"
        :associate-number="associateNumber"
        :multiple-shift-view="true"
        @save-shift-on-multiple-view="handleAddAssociateShift"
      />
    </a-modal>

    <a-modal
      title="Add Several Shifts"
      width="1000px"
      v-model="visible"
      @cancel="cancel"
    >
      <div class="flex flex-column gap-4 mx-5">
        <div class="flex gap-2">
          <a-select
            class="filters-select"
            ref="associateSelect"
            v-model="associateNumber"
            show-search
            :allowClear="true"
            placeholder="Associate"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :dropdown-match-select-width="false"
            :default-active-first-option="false"
            :options="foundAssociates"
            :loading="loadingAssociates"
            @search="fetchAssociates"
            @change="handleSearchAssociatesChange"
            @focus="handleFocusAssociates"
          />
          <a-button
            type="primary"
            :disabled="disableAddNewShift"
            @click="openAssociateShiftModal"
          >
            Add New Shift
          </a-button>
        </div>
        <a-table
          class="multiple-shifts-table"
          size="small"
          :columns="shiftsColumns"
          :data-source="shifts"
          :locale="{ emptyText: 'Please Add Shifts' }"
        >
          <span slot="shift-date" slot-scope="text, record">
            {{ formatDateRange(record.work_start, record.work_end) }}
          </span>
          <span slot="duration" slot-scope="text, record">
            {{ getSignedDuration(record) }}
          </span>
          <span slot="first-lunch" slot-scope="text, record">
            {{ getFirstLunchDuration(record) ?? '-' }}
          </span>
          <span slot="second-lunch" slot-scope="text, record">
            {{ getSecondLunchDuration(record) ?? '-' }}
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-button-group>
              <a-button
                size="small"
                icon="copy"
                @click="initDuplicate(record)"
              />
              <a-button
                size="small"
                icon="delete"
                @click="deleteShift(record)"
              />
            </a-button-group>
          </span>
        </a-table>
        <div class="flex flex-column gap-4">
          <div class="flex gap-2" style="margin-left: 8px">
            <a-checkbox v-model="autoSign" :disabled="disableAutoSignCheckbox"/>
            <span>Sign with my signature</span>
          </div>
          <signature
            v-if="autoSign"
            checkbox-label="I agree that all data is true and correct"
            :associate-name="adminAssociateName"
            :associate-email="adminAssociateEmail"
            :associate-phone="adminAssociatePhoneNumber"
            :available-signature="adminAssociateSignature"
            @checked="setSignatureCheckboxValue"
          />
        </div>
      </div>
      <template slot="footer">
        <div class="flex justify-content-end">
          <a-button
            type="primary"
            :loading="loading"
            :disabled="disableAddShifts"
            @click="create"
          >
            Add Shifts
          </a-button>
          <a-button
            type="danger"
            :disabled="disableCancel"
            @click="cancel"
          >
            Cancel
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {Modal, Button, Select, Checkbox, Switch, Table, Radio, DatePicker, notification} from "ant-design-vue";
import api from "@/api";
import shiftsHelper from "@/helpers/shifts";
import Util from "@/util";
import moment from "moment-timezone";
import AssociateShift from "@/components/associate-shift.vue";
import signature from "@/components/signature.vue";

export default {
  components: {
    signature,
    'associate-shift': AssociateShift,
    'a-table': Table,
    'a-modal': Modal,
    'a-button': Button,
    'a-button-group': Button.Group,
    'a-select': Select,
    'a-checkbox': Checkbox,
    'a-select-option': Select.Option,
    'a-radio-button': Radio.Button,
    'a-radio-group': Radio.Group,
    'a-date-picker': DatePicker
  },
  mixins: [api, shiftsHelper],
  data() {
    return {
      shifts: [],
      foundAssociates: [],
      loadingAssociates: false,
      searchUsersTimeout: undefined,

      associateNumber: undefined,

      shiftsColumns: [
        {
          title: 'Date',
          dataIndex: 'work_start',
          key: 'date',
          scopedSlots: {customRender: 'shift-date'},
          sorter: (a, b) => moment(a.work_start).diff(moment(b.work_start))
        },
        {
          title: 'Duration',
          key: 'duration',
          scopedSlots: {customRender: 'duration'},
          sorter: (a, b) => {
            const durationA = moment(a.work_end).diff(moment(a.work_start));
            const durationB = moment(b.work_end).diff(moment(b.work_start));
            return durationA - durationB;
          }
        },
        {
          title: 'First Lunch',
          key: 'firstLunch',
          scopedSlots: {customRender: 'first-lunch'},
          sorter: (a, b) => {
            if (!a.lunch_start && !b.lunch_start) return 0;
            if (!a.lunch_start) return 1;
            if (!b.lunch_start) return -1;
            return moment(a.lunch_start).diff(moment(b.lunch_start));
          }
        },
        {
          title: 'Second Lunch',
          key: 'secondLunch',
          scopedSlots: {customRender: 'second-lunch'},
          sorter: (a, b) => {
            if (!a.second_lunch_start && !b.second_lunch_start) return 0;
            if (!a.second_lunch_start) return 1;
            if (!b.second_lunch_start) return -1;
            return moment(a.second_lunch_start).diff(moment(b.second_lunch_start));
          }
        },
        {
          title: 'Actions',
          key: 'actions',
          scopedSlots: {customRender: 'actions'}
        }
      ],

      associateShiftComponentKey: 0,
      visibleAssociateShiftModal: false,

      visibleDuplicateShiftModal: false,
      shiftToDuplicate: undefined,
      duplicateType: 'span',

      spanDuplicateDateFrom: undefined,
      spanDuplicateDateTo: undefined,
      singleDuplicateDate: undefined,

      autoSign: false,
      signatureCheckbox: false,

      loading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },
    visualDateFormat() {
      return this.$store.state.applicationState.visualDateFormat;
    },
    projectTimeZone() {
      return this.$store.state.applicationState.timeZone;
    },

    disableAddShifts() {
      return this.loadingAssociates || this.shifts.length === 0
        || (!this.signatureCheckbox && this.autoSign);
    },
    disableAutoSignCheckbox() {
      return this.disableAddShifts;
    },
    disableAddNewShift() {
      return this.loadingAssociates || !this.associateNumber;
    },
    disableCancel() {
      return this.loading;
    },

    spanDuplicate() {
      return this.duplicateType == 'span';
    },
    singleDuplicate() {
      return this.duplicateType == 'single';
    },

    adminAssociateName() {
      return Util.combineAssociateName(
        this.$store.state.applicationState.associate.first_name,
        this.$store.state.applicationState.associate.last_name
      );
    },
    adminAssociateEmail() {
      return this.$store.state.applicationState.associate.email
    },
    adminAssociatePhoneNumber() {
      return this.$store.state.applicationState.associate.phone
    },
    adminAssociateSignature() {
      return this.$store.state.applicationState.associate.signature;
    }
  },
  watch: {
    visible(newVal) {
      this.localVisible = newVal;
    },
    localVisible(newVal) {
      this.$emit('update:visible', newVal);
    },
    shiftToDuplicate() {
      if (!this.shiftToDuplicate) {
        this.visibleDuplicateShiftModal = false;
        return;
      }

      this.visibleDuplicateShiftModal = true;
    }
  },
  methods: {
    successNotification() {
      notification['success']({
        message: 'Success',
        description: 'Associate shifts saved successfully'
      })
    },
    errorNotification(text) {
      notification['warning']({
        message: 'Error',
        description: text ?? 'An error occurred on several shifts creation. Please try again later'
      })
    },

    convertShiftsArrayToDatesString(array, format) {
      return array.map(item => {
        return moment.tz(item.work_start ?? item.scheduled_start, 'UTC').format(format);
      }).join(', ');
    },
    isOverlapExists(resultArray) {
      if (resultArray && resultArray.length > 0) {
        const shifts = resultArray[0].shifts;
        if (!shifts || shifts.length == 0) {
          return false;
        }

        const format = this.$store.state.applicationState.dateTimeFormat;

        notification['warning']({
          message: 'Shift Overlap Warning',
          description: 'One of shifts overlaps with shift(-s) starting at: '
            + this.convertShiftsArrayToDatesString(resultArray[0].shifts, format)
        });

        return true;
      }

      return false;
    },
    async create() {
      this.loading = true;
      const wrappedShifts = this.shifts.map((shift) => {
        return {
          shift: {
            ...shift,
            modified_by_admin: true
          },
          associate: {
            associate_number: this.associateNumber
          }
        }
      });

      console.log(wrappedShifts);

      let overlapCheckSuccess = true;

      for (const record of wrappedShifts) {
        if (!overlapCheckSuccess) {
          break;
        }

        await this.apiCheckShiftConflict(this.associateNumber, record.shift.work_start, record.shift.work_end)
          .then((response) => {
            const data = response.data;

            if (data.error_code != 0) {
              notification['warning']({
                message: 'Shift Overlap Check Error',
                description: 'An error occurred while checking shift overlap. Please try again later.'
              })

              overlapCheckSuccess = false;
              return;
            }

            if (this.isOverlapExists(data.result)) {
              overlapCheckSuccess = false;
            }
          })
      }

      if (!overlapCheckSuccess) {
        this.loading = false;
        return;
      }

      this.apiAddSeveralShifts(wrappedShifts, this.autoSign)
        .then((response) => {
          const data = response.data;
          if (data.error_code != 0) {
            this.errorNotification(data.msg);
          } else {
            this.successNotification();
            this.$emit('close', true);
          }
        })
        .catch(() => this.errorNotification())
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit('close', false);
    },
    handleSearchAssociatesChange(value) {
      this.associateNumber = value;
      this.$refs.associateSelect.blur();
    },
    fetchAssociates(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout);
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.loadingAssociates = true;
        this.apiSearchAssociate(value).then((resp) => {
          this.foundAssociates = resp.data.associate_list.map(user => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id
          }));
          this.loadingAssociates = false;
        });
      }, 1000);
    },
    handleFocusAssociates() {
      if (!this.associateNumber) {
        this.fetchAssociates();
      }
    },

    getSignedDuration(record) {
      const timeRange = this.formatTimeRange(record.work_start, record.work_end);
      return timeRange.start + ' - ' + timeRange.end + ' (' + this.getSignedHours(record) + ')';
    },

    getSignedHours(record) {
      return this.formatWorkHours(
        moment(record.work_start),
        moment(record.work_end),
        record.lunch_start ? moment(record.lunch_start) : null,
        record.lunch_end ? moment(record.lunch_end) : null,
        record.second_lunch_start ? moment(record.second_lunch_start) : null,
        record.second_lunch_end ? moment(record.second_lunch_end) : null
      )
    },

    getFirstLunchDuration(record) {
      if (!record.lunch_start || !record.lunch_end) {
        return null;
      }

      const timeRange = this.formatTimeRange(record.lunch_start, record.lunch_end);
      return timeRange.start + ' - ' + timeRange.end
        + ' (' + this.minutesToHoursAndMinutes(
          this.countDuration(
            moment(record.lunch_start),
            moment(record.lunch_end),
            'minutes'
          )) + ')';
    },

    getSecondLunchDuration(record) {
      if (!record.second_lunch_start || !record.second_lunch_end) {
        return null;
      }

      const timeRange = this.formatTimeRange(record.second_lunch_start, record.second_lunch_end);
      return timeRange.start + ' - ' + timeRange.end
        + ' (' + this.minutesToHoursAndMinutes(
          this.countDuration(
            moment(record.second_lunch_start),
            moment(record.second_lunch_end),
            'minutes'
          )
        ) + ')';
    },

    handleAddAssociateShift(shiftData) {
      this.closeAssociateShiftModal();
      this.shifts.push(shiftData);
    },

    openAssociateShiftModal() {
      this.associateShiftComponentKey += 1;
      this.visibleAssociateShiftModal = true;
    },
    closeAssociateShiftModal() {
      this.visibleAssociateShiftModal = false;
    },

    deleteShift(record) {
      this.shifts = this.shifts.filter((shift) => shift.work_start != record.work_start);
    },
    initDuplicate(record) {
      this.shiftToDuplicate = record;
    },

    confirmDuplicate() {
      if (!this.shiftToDuplicate) return;

      const duplicateShift = (baseShift, daysOffset) => {
        const newShift = {...baseShift};
        newShift.work_start = moment(baseShift.work_start).add(daysOffset, 'days').toISOString();
        newShift.work_end = moment(baseShift.work_end).add(daysOffset, 'days').toISOString();
        if (baseShift.lunch_start) {
          newShift.lunch_start = moment(baseShift.lunch_start).add(daysOffset, 'days').toISOString();
        }
        if (baseShift.lunch_end) {
          newShift.lunch_end = moment(baseShift.lunch_end).add(daysOffset, 'days').toISOString();
        }
        if (baseShift.second_lunch_start) {
          newShift.second_lunch_start = moment(baseShift.second_lunch_start).add(daysOffset, 'days').toISOString();
        }
        if (baseShift.second_lunch_end) {
          newShift.second_lunch_end = moment(baseShift.second_lunch_end).add(daysOffset, 'days').toISOString();
        }
        return newShift;
      };

      const shiftExistsOnDate = (date) => {
        return this.shifts.some(shift => moment(shift.work_start).isSame(date, 'day'));
      };

      if (this.duplicateType === 'single' && this.singleDuplicateDate) {
        const singleDate = moment(this.singleDuplicateDate).startOf('day');
        if (!shiftExistsOnDate(singleDate)) {
          const daysOffset = singleDate.diff(moment(this.shiftToDuplicate.work_start).startOf('day'), 'days');
          this.shifts.push(duplicateShift(this.shiftToDuplicate, daysOffset));
        }
      } else if (this.duplicateType === 'span' && this.spanDuplicateDateFrom && this.spanDuplicateDateTo) {
        let currentDate = moment(this.spanDuplicateDateFrom).startOf('day');
        const endDate = moment(this.spanDuplicateDateTo).startOf('day');
        while (currentDate.isSameOrBefore(endDate, 'day')) {
          if (!shiftExistsOnDate(currentDate)) {
            const daysOffset = currentDate.diff(moment(this.shiftToDuplicate.work_start).startOf('day'), 'days');
            this.shifts.push(duplicateShift(this.shiftToDuplicate, daysOffset));
          }
          currentDate = currentDate.add(1, 'days');
        }
      }

      this.shifts.sort((a, b) => moment(a.work_start).diff(moment(b.work_start)));

      this.shiftToDuplicate = undefined;
    },

    cancelDuplicate() {
      this.shiftToDuplicate = undefined
    },

    disabledDate(current) {
      if (!current) {
        return false;
      }

      const shiftDates = this.shifts.map(
        shift => moment.tz(shift.work_start, this.projectTimeZone).format('YYYY-MM-DD')
      );
      return shiftDates.includes(current.format('YYYY-MM-DD'));
    },
    disabledDateFrom(current) {
      const endValue = this.spanDuplicateDateTo;

      if (!current) {
        return false;
      }

      if (endValue) {
        return this.disabledDate(current) || current.isAfter(endValue, 'day');
      }

      return this.disabledDate(current);
    },
    disabledDateTo(current) {
      const startValue = this.spanDuplicateDateFrom;

      if (!current) {
        return false;
      }

      if (startValue) {
        return this.disabledDate(current) || current.isBefore(startValue, 'day');
      }

      return this.disabledDate(current);
    },

    setSignatureCheckboxValue(e) {
      this.signatureCheckbox = e;
    }
  },
  mounted() {
    this.loadingAssociates = true;
    this.fetchAssociates();
  }
}
</script>

<style scoped>
.content-view-block {
  max-width: unset;
}

.filters-select {
  width: 250px;
}

.filters-select .ant-select-dropdown {
  width: 200px;
}

.filters-select .ant-select-dropdown-menu-item {
  white-space: normal;
  word-wrap: break-word;
}

</style>
