<template>
  <div>
    <a-modal
      v-model="changeCompanyLogoVisible"
      title="Change Company Logo"
      :footer="null"
    >
      <change-company-logo @set-project-logo="handleChangeLogo"/>
    </a-modal>
    <div>
      <div class="project-name-with-logo-container">
        <div
          class="logo-container"
          :style="{backgroundImage: 'url(' + projectAvatarSource + ')'}"
          @click="handleUpdateLogo"
        >
          <a-icon type="camera" class="camera-icon"/>
        </div>
        <h2 class="project-name">{{ project.name }}</h2>
      </div>

      <a-form layout="inline" :form="form">
        <div class="content-view">
          <div class="content-view-block">
            <h3>Company</h3>
            <p>
              <b>Name <span v-if="editingCompany" class="required-field">*</span></b>
              <a-form-item v-if="editingCompany">
                <a-input
                  placeholder="Enter name"
                  v-decorator="['name', {
                      rules: [
                        { required: true, message: 'Please enter the company name!' },
                        { min: 2, max: 100, message: 'The name must be between 2 and 100 characters.' }
                      ]
                    }]"
                />
              </a-form-item>
              <span v-else>{{ project.name }}</span>
            </p>
            <p>
              <b>Description <span v-if="editingCompany" class="required-field">*</span></b>
              <a-form-item v-if="editingCompany">
                <a-textarea
                  placeholder="Enter description"
                  :auto-size="{ minRows: 1, maxRows: 5 }"
                  v-decorator="['description', {rules:[{required: false}]}]"
                />
              </a-form-item>
              <span v-else>{{ project.description }}</span>
            </p>
            <p>
              <b>Owner</b>
              <span>{{ project.owner }}</span>
            </p>
            <p>
              <b>Join Type</b>
              <a-form-item v-if="editingCompany">
                <a-select
                  placeholder="Select join type"
                  v-decorator="['joinType', {
                    rules: [
                      { required: true, message: 'Please select a join type!' }
                    ]
                  }]"
                >
                  <a-select-option value="open">Open</a-select-option>
                  <a-select-option value="request">Request</a-select-option>
                  <a-select-option value="invite">Invite</a-select-option>
                </a-select>
              </a-form-item>
              <span v-else>{{ Util.capitalize(project.join_type) }}</span>
            </p>
            <p>
              <b>Tags</b>
              <a-form-item v-if="editingCompany">
                <a-textarea
                  placeholder="Enter tags"
                  :auto-size="{ minRows: 1, maxRows: 5 }"
                  v-decorator="['tags', {rules:[{required: false}]}]"
                />
              </a-form-item>
              <span v-else>{{ project.tags ?? '-' }}</span>
            </p>
            <p>
              <b>Address <span v-if="editingCompany" class="required-field">*</span></b>
              <span class="flex flex-column gap-2" v-if="editingCompany">
                <a-form-item>
                  <a-input
                    placeholder="Address Line 1"
                    v-decorator="['addressLine1', {
                      rules: [
                        { required: true, message: 'Please enter address line 1!' }
                      ]
                    }]"
                  />
                </a-form-item>

                <a-form-item>
                  <a-input
                    placeholder="Address Line 2"
                    v-decorator="['addressLine2', {rules:[{required: false}]}]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-input
                    placeholder="City"
                    v-decorator="['city', {rules:[
                      { required: true, message: 'Please enter city!' }
                    ]}]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-input
                    placeholder="State"
                    v-decorator="['state', {rules:[{required: false}]}]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-input
                    placeholder="Zipcode"
                    v-decorator="['zipcode', {rules:[
                      { required: true, message: 'Please enter zipcode!' }
                    ]}]"
                  />
                </a-form-item>
              </span>
              <span v-else class="address">{{ projectAddress }}</span>
            </p>
          </div>
          <div class="content-view-block">
            <h3>Active Features</h3>
            <p>
              <b>Shift Confirmation</b>
              <a-form-item v-if="editingCompany">
                <a-switch v-decorator="['shiftConfirmation', {valuePropName: 'checked', rules:[{required: true}]}]"/>
              </a-form-item>
              <span v-else>{{ project.shift_confirmation ? 'Yes' : 'No' }}</span>
            </p>
            <p>
              <b>Require COVID Survey</b>
              <a-form-item v-if="editingCompany">
                <a-switch v-decorator="['requireCovidSurvey', {valuePropName: 'checked', rules:[{required: true}]}]"/>
              </a-form-item>
              <span v-else>{{ project.require_covid_survey ? 'Yes' : 'No' }}</span>
            </p>
            <p>
              <b>Shift Authorizations</b>
              <a-form-item v-if="editingCompany">
                <a-switch v-decorator="['actionAuthorization', {valuePropName: 'checked', rules:[{required: true}]}]"/>
              </a-form-item>
              <span v-else>{{ project.action_authorization ? 'Yes' : 'No' }}</span>
            </p>
            <p>
              <b>SMS Notifications</b>
              <a-form-item v-if="editingCompany">
                <a-switch v-decorator="['sendingSmsWithPush', {valuePropName: 'checked', rules:[{required: true}]}]"/>
              </a-form-item>
              <span v-else>{{ project.sending_sms_with_push ? 'Yes' : 'No' }}</span>
            </p>

            <h3>Contacts</h3>
            <p>
              <b>Email <span v-if="editingCompany" class="required-field">*</span></b>
              <a-form-item v-if="editingCompany">
                <a-input
                  placeholder="Enter email address"
                  v-decorator="['email', {
                      rules: [
                        { required: true, message: 'Please enter the email address!' },
                        { type: 'email', message: 'The input is not a valid email!' }
                      ]
                    }]"
                />
              </a-form-item>
              <span v-else>{{ project.email }}</span>
            </p>
            <p>
              <b>Phone <span v-if="editingCompany" class="required-field">*</span></b>
              <a-form-item v-if="editingCompany">
                <a-input
                  placeholder="Enter phone number"
                  v-decorator="['phone', {
                      rules: [
                        { required: true, message: 'Please enter the phone number!' },
                        { pattern: /^\+?\d{10,15}$/, message: 'The phone number must be 10 to 15 digits long and may start with a +.' }
                      ]
                    }]"
                />
              </a-form-item>
              <span v-else>{{ project.phone }}</span>
            </p>

            <h3>Company Policy</h3>
            <div v-if="project.project_policy_type == 'text'">
              {{ project.project_policy_text ?? 'Company policy is not available' }}
            </div>
            <div v-else-if="project.project_policy_type == 'pdf'">
              <a-button
                icon="download"
                @click="downloadPolicy"
              >
                Download Policy
              </a-button>
            </div>
          </div>
          <div class="content-view-block">
            <template v-if="mainProjectStatistics">
              <h3>Main Info</h3>
              <p>
                <b>Associates Need Validation</b>
                <span>{{ mainProjectStatistics.associates_need_validation }}</span>
              </p>
              <p>
                <b>Associates Completed Onboarding</b>
                <span>{{ mainProjectStatistics.associates_completed_onboarding }}</span>
              </p>
              <p>
                <b>Associates Completed Departure</b>
                <span>{{ mainProjectStatistics.associates_completed_departure }}</span>
              </p>
              <p>
                <b>Unresolved Authorizations</b>
                <span>{{ mainProjectStatistics.unresolved_authorizations }}</span>
              </p>
              <p>
                <b>Payment Requests Awaiting Approval</b>
                <span>{{ mainProjectStatistics.awaiting_approval_payment_requests }}</span>
              </p>
              <p>
                <b>Pending Time Off Requests</b>
                <span>{{ mainProjectStatistics.pending_time_off_requests }}</span>
              </p>
              <p>
                <b>Manually Created Shifts</b>
                <span>{{ mainProjectStatistics.manually_created_shifts }}</span>
              </p>
            </template>

            <template v-if="projectStatistics">
              <h3>Company Settings</h3>
              <p>
                <b>Job Classifications Count</b>
                <span>{{ projectStatistics.project_role_total }}</span>
              </p>
              <p>
                <b>Work Area Count</b>
                <span>{{ projectStatistics.work_area_total }}</span>
              </p>

              <div class="flex gap-2">
                <template v-if="editingCompany">
                  <a-button
                    type="primary"
                    :loading="loading"
                    :disabled="disableSave"
                    @click="handleSubmit"
                  >
                    Save
                  </a-button>
                  <a-button
                    type="danger"
                    :loading="loading"
                    @click="cancelEdit"
                  >
                    Cancel
                  </a-button>
                </template>
                <template v-else>
                  <a-button
                    type="primary"
                    @click="startEdit"
                  >
                    Edit
                  </a-button>
                </template>
              </div>
            </template>
          </div>
        </div>
      </a-form>
      <template v-if="!projectStatistics">
        <div class="loading-spin-project-info">
          <a-spin tip="Loading Project Statistics..."/>
        </div>
      </template>
      <template v-else>
        <div class="content-view">
          <div class="content-view-block">
            <h3>Statistics Params</h3>
            <p>
              <b>In Date Range</b>
              <span>
              <a-switch v-model="statsInRange"/>
            </span>
            </p>
            <template v-if="statsInRange">
              <p>
                <b>Date From</b>
                <span>
              <a-date-picker
                v-model="statsFrom"
                :disabled-date="disabledStartDate"
                :format="visualDateFormat"
                placeholder="From"
                :disabled="loading"
              />
            </span>
              </p>
              <p>
                <b>Date To</b>
                <span>
              <a-date-picker
                v-model="statsTo"
                :disabled-date="disabledEndDate"
                :format="visualDateFormat"
                placeholder="To"
                :disabled="loading"
              />
            </span>
              </p>
            </template>
            <p>
              <b>Job Classification</b>
              <span>
              <a-select
                v-model="statsRole"
                class="role-select"
                placeholder="Job Classification"
                :allow-clear="true"
                :default-value="-1"
              >
              <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </a-select-option>
            </a-select>
            </span>
            </p>
          </div>
          <div class="content-view-block">
            <h3 class="flex gap-2 align-items-center">
              Associates
              <a-icon class="open-view" type="select" @click="openAssociates"/>
            </h3>
            <p>
              <b>Total</b>
              <span>{{ associatesTotal }}</span>
            </p>
            <p>
              <b>Need Validation</b>
              <span>{{ projectStatistics.associates_need_validation }}</span>
            </p>
            <p v-if="projectStatistics.associates_completed_onboarding">
              <b>Completed Onboarding</b>
              <span>{{ projectStatistics.associates_completed_onboarding }}</span>
            </p>
            <p v-if="projectStatistics.associates_completed_departure">
              <b>Completed Departure</b>
              <span>{{ projectStatistics.associates_completed_departure }}</span>
            </p>

            <div class="flex flex-row gap-2">
              <h4>Activity Information</h4>
              <a-icon class="open-view" type="select" @click="openActivity"/>
            </div>
            <p>
              <b>Working Now</b>
              <span>{{ associatesWorkingNow }}</span>
            </p>
            <p>
              <b>On 1st Lunch</b>
              <span>{{ associatesOnFirstLunch }}</span>
            </p>
            <p>
              <b>On 2nd Lunch</b>
              <span>{{ associatesOnSecondLunch }}</span>
            </p>
            <p>
              <b>Outside</b>
              <span>{{ associatesOutside }}</span>
            </p>

            <h4>By Payment Types</h4>
            <bar-chart
              :chart-data="associateByPaymentTypesChartData"
            />
            <p v-for="pt in associateByPaymentTypes" :key="countKey(pt)">
              <b>{{ pt.item }}</b>
              <span>{{ pt.count }}</span>
            </p>

            <template v-if="!statsRole">
              <h4>By Job Classifications</h4>
              <div class="roles-list">
                <p v-for="role in associateByRoles" :key="role.item.id">
                  <b>{{ role.item.name }}</b>
                  <span>{{ role.count }}</span>
                </p>
              </div>
            </template>

            <h4>Problematic</h4>
            <bar-chart
              :chart-data="problematicAssociatesChartData"
            />
            <p v-for="pa in problematicAssociates" :key="countKey(pa)">
              <b>{{ getProblematicAssociatesCategoryLabel(pa.item) }}</b>
              <span>{{ pa.count }}</span>
            </p>

            <h3>Authorizations</h3>
            <p>
              <b>Total</b>
              <span>{{ authorizationsTotal }}</span>
            </p>
            <p>
              <b>Awaiting Review</b>
              <span>{{ projectStatistics.unresolved_authorizations }}</span>
            </p>

            <h4>By Types</h4>
            <bar-chart
              :chart-data="authsChartData"
            />
            <p v-for="au in authorizationsByTypes" :key="countKey(au)">
              <b>{{ getAuthTypeLabel(au.item) }}</b>
              <span>{{ au.count }}</span>
            </p>

            <h3>Time Off Requests</h3>
            <bar-chart
              :chart-data="timeOffRequestsChartData"
            />
            <p>
              <b>Pending</b>
              <span>{{ pendingTimeOffRequests }}</span>
            </p>
            <p>
              <b>Approved</b>
              <span>{{ approvedTimeOffRequests }}</span>
            </p>
            <p>
              <b>Declined</b>
              <span>{{ declinesTimeOffRequests }}</span>
            </p>

            <h4>By Absence Statuses</h4>
            <bar-chart
              :chart-data="timeOffRequestsByAbsenceStatusChartData"
            />
            <p v-for="as in timeOffRequestsByAbsenceStatus" :key="countKey(as)">
              <b>{{ getShiftStatusLabel(as.item) }}</b>
              <span>{{ as.count }}</span>
            </p>
          </div>
          <div class="content-view-block">
            <h3>
              Shifts
              <a-icon class="open-view" type="select" @click="openShifts"/>
            </h3>
            <p>
              <b>Total</b>
              <span>{{ shiftsTotal }}</span>
            </p>
            <p>
              <b>With Unresolved Auths</b>
              <span>{{ projectStatistics.shifts_with_unresolved_authorization }}</span>
            </p>

            <h4>By Statuses</h4>
            <bar-chart
              :chart-data="shiftStatusesChartData"
            />
            <p v-for="st in shiftsByStatuses" :key="countKey(st)">
              <b>{{ getShiftStatusLabel(st.item) }}</b>
              <span>{{ st.count }}</span>
            </p>

            <h4>By Payment Statuses</h4>
            <bar-chart
              :chart-data="shiftPaymentStatusesChartData"
            />
            <p v-for="ps in paymentStatuses" :key="countKey(ps)">
              <b>{{ getPaymentStatusLabel(ps.item) }}</b>
              <span>{{ ps.count }}</span>
            </p>

            <h3>Tasks</h3>
            <p>
              <b>Assigned</b>
              <span>{{ projectStatistics.assigned_tasks }}</span>
            </p>
            <p>
              <b>Billable</b>
              <span>{{ projectStatistics.tasks_with_billable_customers }}</span>
            </p>
            <h3>
              Payment Requests
              <a-icon class="open-view" type="select" @click="openPaymentRequests"/>
            </h3>
            <p>
              <b>In Progress</b>
              <span>{{ projectStatistics.in_progress_payment_requests }}</span>
            </p>
            <p>
              <b>Awaiting Approval</b>
              <span>{{ projectStatistics.awaiting_approval_payment_requests }}</span>
            </p>
            <p>
              <b>Awaiting Payment</b>
              <span>{{ projectStatistics.awaiting_payment_payment_requests }}</span>
            </p>
            <p>
              <b>Paid</b>
              <span>${{ projectStatistics.total_paid / 100 }}</span>
            </p>

            <h4>By Payment Statuses</h4>
            <bar-chart :chart-data="paymentRequestsByPaymentStatusesChartData"/>
            <p v-for="pr in paymentRequestsByPaymentStatuses" :key="countKey(pr)">
              <b>{{ getPrPaymentStatusLabel(pr.item) }}</b>
              <span>{{ pr.count }}</span>
            </p>

            <h4>By Payment Methods</h4>
            <bar-chart :chart-data="paymentRequestsByPaymentMethodsChartData"/>
            <p v-for="pr in paymentRequestByPaymentMethods" :key="countKey(pr)">
              <b>{{ getPaymentMethodLabel(pr.item) }}</b>
              <span>{{ pr.count }}</span>
            </p>

            <h3>Documents</h3>
            <p>
              <b>Total</b>
              <span>{{ projectStatistics.document_total }}</span>
            </p>
            <p>
              <b>Onboarding Documents</b>
              <span>{{ projectStatistics.onboarding_document_total }}</span>
            </p>
            <p>
              <b>Departure Documents</b>
              <span>{{ projectStatistics.departure_document_total }}</span>
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Util from "@/util";
import api from "@/api";
import {notification, Switch, DatePicker, Spin, Select, Icon, Button, Form, Input, Modal, Menu} from "ant-design-vue";
import BarChart from "@/components/bar-chart.vue";
import moment from "moment-timezone";
import store from "@/store";
import ChangeCompanyLogo from "@/components/change-company-logo.vue";

import {
  authorizationsMap,
  shiftStatusesMap,
  paymentStatusesMap,
  paymentRequestPaymentStatusesMap,
  paymentMethodsMap,
  problematicAssociatesMap, vueTourOptions
} from "@/const";

function countComparator(a, b) {
  return b.count - a.count;
}

const PAYMENT_TYPES_COLORS = ['#4CAF50', '#2196F3', '#FFC107'];
const PROBLEMATIC_ASSOCIATES_COLORS = ['#7f8c8d', '#34495e', '#8e44ad'];
const TIME_OFF_REQUESTS_COLORS = PAYMENT_TYPES_COLORS;

export default {
  components: {
    ChangeCompanyLogo,
    'a-button': Button,
    'a-date-picker': DatePicker,
    'a-select': Select,
    'a-select-option': Select.Option,
    'bar-chart': BarChart,
    'a-switch': Switch,
    'a-spin': Spin,
    'a-icon': Icon,
    'a-form': Form,
    'a-form-item': Form.Item,
    'a-input': Input,
    'a-textarea': Input.TextArea,
    'a-modal': Modal,
  },
  mixins: [api],
  data() {
    return {
      projectStatistics: undefined,

      statsInRange: false,

      statsFrom: undefined,
      statsTo: undefined,
      statsRole: undefined,

      form: undefined,
      loading: false,

      editingCompany: false,
      fieldsTouched: false,

      changeCompanyLogoVisible: false,

      mainProjectStatistics: undefined,

      projectAvatarSource: require('@/assets/img/avatar-placeholder.svg'),
    }
  },
  watch: {
    statsInRange() {
      if (!this.statsInRange && (this.statsFrom || this.statsTo)) {
        this.statsFrom = undefined;
        this.statsTo = undefined;

        this.getProjectStatistics();
      }
    },
    statsFrom() {
      this.getProjectStatistics();
    },
    statsTo() {
      this.getProjectStatistics();
    },
    statsRole() {
      this.getProjectStatistics();
    }
  },
  computed: {
    vueTourOptions() {
      return vueTourOptions
    },
    Util() {
      return Util
    },

    project() {
      return this.$store.state.applicationState.currentProject
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },

    projectLogoUrlAvailable() {
      return this.project.logo_url != null;
    },

    combinedActiveFeatures() {
      const activeFeatures = []

      if (this.project.require_covid_survey === true) {
        activeFeatures.push("Require COVID Survey")
      }
      if (this.project.action_authorization === true) {
        activeFeatures.push("Action Authorization")
      }
      if (this.project.onboarding_feature_status === "enabled") {
        activeFeatures.push("Onboarding")
      }
      if (this.project.departure_feature_status === "enabled") {
        activeFeatures.push("Departure")
      }
      let combined = "";

      activeFeatures.forEach((feature, idx) => {
        combined += feature
        if (idx !== activeFeatures.length - 1) {
          combined += ", "
        }
      })

      return combined;
    },

    statsFromString() {
      if (this.statsFrom === null) {
        return null;
      }

      return Util.convertDateTimeToUTC(this.statsFrom);
    },
    statsToString() {
      if (this.statsTo === null) {
        return null;
      }

      return Util.convertDateTimeToUTC(this.statsTo);
    },

    chartOptions() {
      return this.$store.state.applicationState.chartOptions;
    },

    associatesTotal() {
      return this.projectStatistics.associate_by_payment_types.reduce((acc, cur) => acc + cur.count, 0);
    },
    associatesWorkingNow() {
      const result = this.projectStatistics.associate_by_statuses.filter((status) => status.item == 'work');
      if (!result.length) {
        return 0;
      }

      return result[0].count;
    },
    associatesOnFirstLunch() {
      const result = this.projectStatistics.associate_by_statuses.filter((status) => status.item == 'lunch');
      if (!result.length) {
        return 0;
      }

      return result[0].count;
    },
    associatesOnSecondLunch() {
      const result = this.projectStatistics.associate_by_statuses.filter((status) => status.item == 'second_lunch');
      if (!result.length) {
        return 0;
      }

      return result[0].count;
    },
    associatesOutside() {
      const result = this.projectStatistics.associate_by_statuses.filter((status) => status.item == 'outside');
      if (!result.length) {
        return 0;
      }

      return result[0].count;
    },
    shiftsTotal() {
      return this.projectStatistics.shifts_with_resolved_authorization
        + this.projectStatistics.shifts_with_unresolved_authorization;
    },
    authorizationsTotal() {
      return this.projectStatistics.unresolved_authorizations
        + this.projectStatistics.resolved_authorizations;
    },

    associateByPaymentTypes() {
      return this.projectStatistics.associate_by_payment_types.sort(countComparator);
    },
    associateByRoles() {
      return this.projectStatistics.associate_by_roles.sort(countComparator);
    },
    shiftsByStatuses() {
      return this.projectStatistics.shift_by_statuses.sort(countComparator);
    },
    problematicAssociates() {
      return this.projectStatistics.problematic_associates.sort(countComparator);
    },
    paymentStatuses() {
      return this.projectStatistics.shift_by_payment_statuses.sort(countComparator);
    },
    authorizationsByTypes() {
      return this.projectStatistics.authorization_by_types.sort(countComparator);
    },
    timeOffRequests() {
      return this.projectStatistics.time_off_requests_by_status.sort(countComparator);
    },
    timeOffRequestsByAbsenceStatus() {
      return this.projectStatistics.time_off_requests_by_absence_status.sort(countComparator);
    },
    paymentRequestsByPaymentStatuses() {
      return this.projectStatistics.payment_request_by_payment_statuses.sort(countComparator);
    },
    paymentRequestByPaymentMethods() {
      return this.projectStatistics.payment_request_by_payment_methods.sort(countComparator);
    },

    pendingTimeOffRequests() {
      const pending = this.timeOffRequests.find((tor) => tor.item == 'pending');
      return pending ? pending.count : 0;
    },
    approvedTimeOffRequests() {
      const approved = this.timeOffRequests.find((tor) => tor.item == 'approved');
      return approved ? approved.count : 0;
    },
    declinesTimeOffRequests() {
      const declined = this.timeOffRequests.find((tor) => tor.item == 'declined');
      return declined ? declined.count : 0;
    },

    associateByPaymentTypesChartData() {
      const labels = [];
      const data = [];

      this.associateByPaymentTypes.forEach((pt) => {
        labels.push(pt.item);
        data.push(pt.count);
      })

      return {
        labels,
        datasets: [
          {
            label: 'Associates Count',
            backgroundColor: PAYMENT_TYPES_COLORS,
            data
          }
        ]
      }
    },

    problematicAssociatesChartData() {
      const labels = [];
      const data = [];

      this.problematicAssociates.forEach((pt) => {
        if (pt.count > 0) {
          labels.push(this.getProblematicAssociatesCategoryLabel(pt.item));
          data.push(pt.count);
        }
      })

      return {
        labels,
        datasets: [
          {
            label: 'Associates Count',
            backgroundColor: PROBLEMATIC_ASSOCIATES_COLORS,
            data
          }
        ]
      }
    },

    shiftStatusesChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.shiftsByStatuses.forEach((st) => {
        labels.push(this.getShiftStatusLabel(st.item));
        colors.push(this.getShiftStatusColor(st.item));
        data.push(st.count);
      })

      return {
        labels,
        datasets: [
          {
            label: 'Shifts Count',
            backgroundColor: colors,
            data
          }
        ]
      }
    },

    shiftPaymentStatusesChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.paymentStatuses.forEach(pt => {
        labels.push(this.getPaymentStatusLabel(pt.item));
        colors.push(this.getPaymentStatusColor(pt.item));
        data.push(pt.count);
      });

      return {
        labels,
        datasets: [
          {
            label: 'Shifts Count',
            backgroundColor: colors,
            data
          }
        ]
      }
    },

    authsChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.authorizationsByTypes.forEach(au => {
        labels.push(this.getAuthTypeLabel(au.item));
        colors.push(this.getAuthTypeColor(au.item));
        data.push(au.count);
      });

      return {
        labels,
        datasets: [
          {
            label: 'Auths Count',
            backgroundColor: colors,
            data
          }
        ]
      }
    },

    timeOffRequestsChartData() {
      return {
        labels: ['Pending', 'Approved', 'Declined'],
        datasets: [
          {
            label: 'Requests Count',
            backgroundColor: TIME_OFF_REQUESTS_COLORS,
            data: [this.pendingTimeOffRequests, this.approvedTimeOffRequests, this.declinesTimeOffRequests]
          }
        ]
      }
    },

    timeOffRequestsByAbsenceStatusChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.timeOffRequestsByAbsenceStatus.forEach((st) => {
        labels.push(this.getShiftStatusLabel(st.item));
        colors.push(this.getShiftStatusColor(st.item));
        data.push(st.count);
      })

      return {
        labels,
        datasets: [
          {
            label: 'Requests Count',
            backgroundColor: colors,
            data
          }
        ]
      }
    },

    paymentRequestsByPaymentStatusesChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.paymentRequestsByPaymentStatuses.forEach(pr => {
        labels.push(this.getPrPaymentStatusLabel(pr.item));
        colors.push(this.getPrPaymentStatusColor(pr.item));
        data.push(pr.count);
      });

      return {
        labels,
        datasets: [
          {
            label: 'Requests Count',
            backgroundColor: colors,
            data
          }
        ]
      }
    },

    paymentRequestsByPaymentMethodsChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.paymentRequestByPaymentMethods.forEach(pr => {
        labels.push(this.getPaymentMethodLabel(pr.item));
        colors.push(this.getPaymentMethodColor(pr.item));
        data.push(pr.count);
      });

      return {
        labels,
        datasets: [
          {
            label: 'Requests Count',
            backgroundColor: colors,
            data
          }
        ]
      }
    },

    projectAddress() {
      if (!(this.project || this.project.address)) {
        return '-';
      }

      const address = this.project.address;
      let addressString = address.address_line1;

      if (address.address_line2) {
        addressString += '\n' + address.address_line2;
      }
      if (address.city) {
        addressString += '\n' + address.city;
      }
      if (address.state) {
        addressString += '\n' + address.state;
      }
      if (address.zipcode) {
        addressString += '\n' + address.zipcode;
      }

      return addressString;
    },

    disableSave() {
      return !this.form || !this.fieldsTouched;
    }
  },
  methods: {
    getProjectStatistics() {
      this.loading = true;
      this.projectStatistics = undefined;
      this.apiGetProjectStatistics(this.statsFromString, this.statsToString, this.statsRole)
        .then((response) => this.projectStatistics = response.data.statistic)
        .catch(() => notification['warning']({
          message: 'Project Stats Load Error',
          description: 'Please try again later'
        }))
        .finally(() => this.loading = false);
    },
    getMainProjectStatistics() {
      this.mainProjectStatistics = undefined;

      this.apiGetMainProjectStatistics(this.statsFromString, this.statsToString, this.statsRole)
        .then((response) => {
          this.mainProjectStatistics = response.data.statistic;
        })
        .catch(() => notification['warning']({
          message: 'Project Main Stats Load Error',
          description: 'Please try again later'
        }));
    },

    getProblematicAssociatesCategoryLabel(item) {
      return problematicAssociatesMap[item] ?? item;
    },

    getShiftStatusLabel(item) {
      return shiftStatusesMap[item] ? shiftStatusesMap[item].label : item;
    },
    getShiftStatusColor(item) {
      return shiftStatusesMap[item] ? shiftStatusesMap[item].color : '#bbbbbb';
    },

    getPaymentStatusLabel(status) {
      return paymentStatusesMap[status] ? paymentStatusesMap[status].label : status;
    },
    getPaymentStatusColor(status) {
      return paymentStatusesMap[status] ? paymentStatusesMap[status].color : '#bbbbbb';
    },

    getPrPaymentStatusLabel(status) {
      return paymentRequestPaymentStatusesMap[status] ?
        paymentRequestPaymentStatusesMap[status].label : status;
    },
    getPrPaymentStatusColor(status) {
      return paymentRequestPaymentStatusesMap[status] ?
        paymentRequestPaymentStatusesMap[status].color : '#bbbbbb';
    },

    getPaymentMethodLabel(method) {
      return paymentMethodsMap[method] ? paymentMethodsMap[method].label : method;
    },
    getPaymentMethodColor(method) {
      return paymentMethodsMap[method] ? paymentMethodsMap[method].color : method;
    },

    getAuthTypeLabel(type) {
      return authorizationsMap[type] ? authorizationsMap[type].label : type;
    },
    getAuthTypeColor(status) {
      return authorizationsMap[status] ? authorizationsMap[status].color : '#bbbbbb';
    },

    initForm() {
      this.form = this.$form.createForm(this, {
        name: 'company_edit_form',
        onFieldsChange: (_) => {
          this.fieldsTouched = true;
        },
        mapPropsToFields: () => {
          return {
            name: this.$form.createFormField({
              value: this.project.name
            }),
            description: this.$form.createFormField({
              value: this.project.description
            }),
            joinType: this.$form.createFormField({
              value: this.project.join_type
            }),
            tags: this.$form.createFormField({
              value: this.project.tags
            }),
            addressLine1: this.$form.createFormField({
              value: this.project.address.address_line1
            }),
            addressLine2: this.$form.createFormField({
              value: this.project.address.address_line2
            }),
            city: this.$form.createFormField({
              value: this.project.address.city
            }),
            state: this.$form.createFormField({
              value: this.project.address.state
            }),
            zipcode: this.$form.createFormField({
              value: this.project.address.zipcode
            }),
            email: this.$form.createFormField({
              value: this.project.email
            }),
            phone: this.$form.createFormField({
              value: this.project.phone
            }),
            shiftConfirmation: this.$form.createFormField({
              value: this.project.shift_confirmation
            }),
            requireCovidSurvey: this.$form.createFormField({
              value: this.project.require_covid_survey
            }),
            actionAuthorization: this.$form.createFormField({
              value: this.project.action_authorization
            }),
            sendingSmsWithPush: this.$form.createFormField({
              value: this.project.sending_sms_with_push
            })
          }
        }
      });
    },

    disabledStartDate(startValue) {
      const endValue = this.statsTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.statsFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    openPaymentRequests() {
      this.$router.push('/payments');
    },
    openAssociates() {
      this.$router.push('/associate-profiles');
    },
    openActivity() {
      this.$router.push('/activity');
    },
    openShifts() {
      this.$router.push('/shifts');
    },

    startEdit() {
      this.editingCompany = true;
    },
    cancelEdit() {
      this.editingCompany = false;
      this.fieldsTouched = false;
      this.initForm();
    },

    showIncorrectFormError(msg) {
      notification.warning({
        message: 'Incorrect Form',
        description: msg ?? 'Some fields are not filled in or are filled in incorrectly. Please check the fields'
      })
    },

    showUpdateSuccessMessage() {
      notification.success({
        message: 'Company Info Updated'
      })
    },
    showUpdateErrorMessage() {
      notification.warning({
        message: 'Company Info Update Error',
        description: 'Please try again later'
      })
    },

    showUpdateIconSuccessMessage() {
      notification.success({
        message: 'Company Icon Updated'
      })
    },
    showUpdateIconErrorMessage() {
      notification.warning({
        message: 'Company Icon Update Error',
        description: 'Please try again later'
      })
    },

    handleChangeLogo(logoFileId) {
      this.changeCompanyLogoVisible = false;
      this.loading = true;

      const projectFields = this.project;

      this.apiSetProject(projectFields, logoFileId)
        .then(async (response) => {
          const data = response.data;

          if (data.error_code != 0) {
            this.showUpdateIconErrorMessage();
            return;
          }

          this.showUpdateIconSuccessMessage();
          await store.dispatch('loadApplicationState');
        })
        .finally(() => {
          this.loading = false;
        })
    },

    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (err) {
          this.showIncorrectFormError();
          return;
        }

        this.loading = true;

        const projectFields = {
          id: this.project.id,
          schedule_integration_url: this.project.schedule_integration_url,
          name: values.name,
          description: values.description,
          email: values.email,
          phone: values.phone,
          require_covid_survey: values.requireCovidSurvey,
          shift_confirmation: values.shiftConfirmation,
          action_authorization: values.actionAuthorization,
          sending_sms_with_push: values.sendingSmsWithPush,
          join_type: values.joinType,
          tags: values.tags,
          address: {
            address_line1: values.addressLine1,
            address_line2: values.addressLine2,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode
          }
        }

        this.apiSetProject(projectFields)
          .then(async (response) => {
            const data = response.data;

            if (data.error_code != 0) {
              this.showUpdateErrorMessage();
              return;
            }

            this.showUpdateSuccessMessage();
            await store.dispatch('loadApplicationState');
          })
          .finally(() => {
            this.loading = false;
            this.cancelEdit();
          })
      })
    },

    countKey(pt) {
      return pt.count + pt.item + Math.floor(Math.random() * 1000);
    },

    handleUpdateLogo() {
      this.changeCompanyLogoVisible = true;
    },

    tryToGetAvatar() {
      if (this.projectLogoUrlAvailable) {
        const img = new Image();
        const imgPath = this.project.logo_url;
        img.onload = () => {
          this.projectAvatarSource = imgPath;
        };
        img.onerror = () => {
          this.projectAvatarSource = require('@/assets/img/avatar-placeholder.svg');
        };
        img.src = imgPath;
      }
    },

    downloadPolicy() {
      window.open(this.project.project_policy_text, '_blank')
    }
  },
  mounted() {
    this.initForm();
    this.getMainProjectStatistics();
    this.getProjectStatistics();
    this.tryToGetAvatar();

  }
}
</script>

<style scoped>
.content-view-block {
  width: 365px;
}

.active-features {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.active-features-list {
  text-align: right;
  max-width: 250px;
  overflow-wrap: break-word;
}

.roles-list {
  max-height: 260px;
  overflow-y: auto;
}

.roles-list p {
  margin-right: 5px;
}

.loading-spin-project-info {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.open-view {
  font-size: 12px;
}

.open-view:hover {
  cursor: pointer;
}

.address {
  white-space: pre-wrap;
}

.join-type-select {
  min-width: 100px;
}

.project-name-with-logo-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.project-name {
  margin: unset;
}

.ant-form-inline .ant-form-item {
  margin-right: 0;
  max-width: 170px;
}

.logo-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

.camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.logo-container:hover .camera-icon {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.role-select {
  width: 150px;
}

</style>
