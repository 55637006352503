<template>
  <div>
    <h2 class="view-header">Payroll</h2>
    <div class="content-view">
      <div class="content-view-block" v-if="currentPayroll">
        <div>
          <h3 class="content-subheader">
            Payroll Information
          </h3>
          <p v-if="currentPayroll.id != 'new'">
            <b>ID</b>
            <span>{{ currentPayroll.id }}</span>
          </p>
          <p>
            <b>Name</b>
            <span v-if="!editMode">{{ currentPayroll.name }}</span>
            <span v-else>
              <a-input class="payroll-name-field" v-model="currentPayroll.name"/>
            </span>
          </p>
          <p>
            <b>Start Date</b>
            <span v-if="!editMode">{{ formattedPayrollStartDate }}</span>
            <span v-else class="flex flex-column align-items-center gap-1">
              <a-date-picker
                class="payroll-date-picker"
                v-model="currentPayroll.startDate"
                placeholder="Start Date"
                :disabled-date="disabledStartDate"
                :format="dateFormat"
              />
            </span>
          </p>
          <p>
            <b>End Date</b>
            <span v-if="!editMode">{{ formattedPayrollEndDate }}</span>
            <span v-else class="flex flex-column align-items-center gap-1">
              <a-date-picker
                class="payroll-date-picker"
                v-model="currentPayroll.endDate"
                placeholder="End Date"
                :disabled-date="disabledEndDate"
                :format="dateFormat"
              />
            </span>
          </p>
        </div>
        <div class="flex flex-row gap-2">
          <template v-if="!editMode">
            <a-button type="primary" @click="switchEditMode">Edit</a-button>
            <a-button @click="back">Back</a-button>
          </template>
          <template v-else>
            <a-button type="primary" @click="save" :disabled="disabledSave">{{ isNew ? 'Add' : 'Save' }}</a-button>
            <a-button v-if="!isNew" @click="cancel">Cancel</a-button>
          </template>
        </div>
      </div>
      <div class="content-view-block">
        <div>
          <h3 class="content-subheader">
            {{ editMode ? 'Attach Documents' : 'Attached Documents' }}
          </h3>
          <p v-if="editMode">
            <payroll-documents-upload @documents-uploaded="handleDocumentsUploaded"/>
          </p>
          <p v-else>
            <a-table
              size="small"
              class="document-table"
              :columns="columns"
              :data-source="currentPayroll.documents"
              :loading="loading"
              rowKey="id"
            >
              <span slot="actions" slot-scope="text, record">
                <a-button-group>
                  <a-dropdown v-if="record.files">
                    <a-button icon="download"/>
                    <a-menu slot="overlay">
                      <a-menu-item v-for="file in record.files">
                        <a :href="file.url" target="_blank">File #{{ record.files.indexOf(file) + 1 }}</a>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                  <a-button icon="delete" @click="deleteDocument(record.id, record.name)"/>
                </a-button-group>
              </span>
            </a-table>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import antd, {Button, DatePicker, Dropdown, Input, Menu, Modal, notification, Table} from "ant-design-vue";
import Util from "@/util";
import store from "@/store";
import moment from 'moment-timezone';
import PayrollDocumentsUpload from "@/components/payroll-documents-upload.vue";

export default {
  components: {
    'a-table': Table,
    'a-input': Input,
    'a-button': Button,
    'a-button-group': Button.Group,
    'a-modal': Modal,
    'a-dropdown': Dropdown,
    'a-menu': Menu,
    'a-menu-item': Menu.Item,
    'a-date-picker': DatePicker,

    'payroll-documents-upload': PayrollDocumentsUpload
  },
  mixins: [api, Util],
  data() {
    return {
      currentPayroll: {
        id: undefined,
        name: undefined,
        startDate: undefined,
        endDate: undefined,
        documents: []
      },
      initialPayrollState: {},

      editMode: false,

      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Actions',
          key: 'actions',
          scopedSlots: {customRender: 'actions'}
        }
      ],

      loading: false,

      storedPayroll: {},

      documentsToUpload: [],

      dateFormat: store.state.applicationState.dateFormat,
      serverDateFormat: store.state.applicationState.serverDateFormat,

      addDocumentsVisible: false
    }
  },
  computed: {
    isNew() {
      return this.currentPayroll.id == 'new';
    },
    formattedPayrollStartDate() {
      return this.currentPayroll.startDate ? this.currentPayroll.startDate.format(this.dateFormat) : 'Not Available';
    },
    formattedPayrollEndDate() {
      return this.currentPayroll.endDate ? this.currentPayroll.endDate.format(this.dateFormat) : 'Not Available';
    },

    serverFormattedPayrollStartDate() {
      return this.currentPayroll.startDate ? this.currentPayroll.startDate.startOf('day').utc().toISOString() : null;
    },
    serverFormattedPayrollEndDate() {
      return this.currentPayroll.endDate ? this.currentPayroll.endDate.startOf('day').utc().toISOString() : null;
    },

    disabledSave() {
      return (this.currentPayroll.name != null && this.currentPayroll.name.length == 0)
        || this.currentPayroll.startDate == null
        || this.currentPayroll.endDate == null
        || this.currentPayroll.documents == null;
    }
  },
  methods: {
    loadPayrollData() {
      this.documentsToUpload = [];
      this.apiGetPayroll(this.currentPayroll.id)
        .then((response) => {
          if (response.data.error_code != 0) {
            this.showNotification('error', 'Error', 'An error occurred while loading payroll');
            return;
          }

          const data = response.data.payroll;

          this.currentPayroll.name = data.name;
          this.currentPayroll.startDate = moment(data.start_date);
          this.currentPayroll.endDate = moment(data.end_date);

          this.currentPayroll.documents = data.documents;
        })
        .catch(() => this.showNotification('error', 'Error', 'An error occurred while loading payroll'))
    },

    deleteDocument(id, name) {
      antd.Modal.confirm({
        title: 'Are you sure?',
        content: `You are about to delete the payroll document "${name}". Delete it?`,
        onOk: () => {
          console.log()
          this.apiDeletePayrollDocument(id)
            .then((response) => {
              if (response.body.error_code == 0) {
                this.loadPayrollData()
                this.showNotification('success', 'Success', `Payroll document "${name}" has been successfully deleted`)
              } else {
                this.showNotification('error', 'Error', 'An error occurred while deleting payroll document');
              }
            });
        },
        onCancel() {
        }
      })
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    back() {
      this.$router.push({path: '/payrolls'})
    },

    updateDate(newStartDate, newEndDate) {
      this.currentPayroll.startDate = moment(newStartDate);
      this.currentPayroll.endDate = moment(newEndDate);
    },
    saveInitialState() {
      this.initialPayrollState = JSON.parse(JSON.stringify(this.currentPayroll));
    },
    returnInitialState() {
      if (this.initialPayrollState) {
        Object.assign(this.currentPayroll, this.initialPayrollState);
        this.updateDate(this.initialPayrollState.startDate, this.initialPayrollState.endDate);

        this.initialPayrollState = undefined;
      }

      this.documentsToUpload = [];
    },
    switchEditMode() {
      if (!this.editMode) {
        this.saveInitialState();
      }

      this.editMode = !this.editMode;
    },

    handleDocumentsUploaded(documents) {
      this.documentsToUpload = documents;
    },

    save() {
      if (this.isNew) {
        this.apiCreatePayroll(
          this.currentPayroll.name,
          this.serverFormattedPayrollStartDate,
          this.serverFormattedPayrollEndDate,
          this.documentsToUpload
        )
          .then((response) => {
            if (response.data.error_code != 0) {
              this.showNotification('error', 'Error', 'An error occurred when creating a payroll. Please try again')
              return;
            }

            this.currentPayroll.id = response.data.payroll.id;
            this.$router.push({path: '/payroll', query: {id: this.currentPayroll.id}})

            this.switchEditMode();
            this.loadPayrollData();
          })
          .catch((err) => {
            this.showNotification('error', 'Error', 'An error occurred when updating a payroll. Please try again')
          })
      } else {
        this.apiUpdatePayroll(
          Number(this.currentPayroll.id),
          this.currentPayroll.name,
          this.serverFormattedPayrollStartDate,
          this.serverFormattedPayrollEndDate,
          this.documentsToUpload
        )
          .then((response) => {
            if (response.data.error_code != 0) {
              this.showNotification('error', 'Error', 'An error occurred when updating a payroll. Please try again')
              return;
            }

            this.switchEditMode();
            this.loadPayrollData();
          })
          .catch((err) => {
            this.showNotification('error', 'Error', 'An error occurred when updating a payroll. Please try again')
          })
      }
    },
    cancel() {
      this.returnInitialState();
      this.switchEditMode();
    },

    getCurrentDate() {
      return moment().endOf('day');
    },
    disabledStartDate(startValue) {
      const endValue = this.currentPayroll.endDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.isAfter(endValue, 'day');
    },
    disabledEndDate(endValue) {
      const startValue = this.currentPayroll.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return endValue.isBefore(startValue, 'day');
    }

  },
  mounted() {
    if (this.$route.query.id) {
      this.currentPayroll.id = this.$route.query.id;
    }

    if (this.isNew) {
      this.editMode = true;
    } else {
      this.loadPayrollData();
    }
  }
}
</script>

<style scoped>
.document-table {
  width: 300px;
}

::v-deep .ant-empty-description {
  display: block;
}
</style>
