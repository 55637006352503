<template>
  <a-modal
    title="Generate Payroll Report"
    v-model="visibleModal"
    :footer="null"
    @cancel="$emit('cancel')"
  >
    <div class="flex flex-column gap-2">
      <div class="content-view">
        <div class="content-view-block">
          <p>
            <b>Date From</b>
            <a-date-picker
              class="payroll-date-picker"
              v-model="dateFrom"
              placeholder="Start Date"
              :disabled-date="disabledStartDate"
              :format="dateFormat"
            />
          </p>
          <p>
            <b>Date To</b>
            <a-date-picker
              class="payroll-date-picker"
              v-model="dateTo"
              placeholder="End Date"
              :disabled-date="disabledEndDate"
              :format="dateFormat"
            />
          </p>
          <p>
            <b>Format</b>
            <a-radio-group v-model="reportFormat">
              <a-radio value="excel">
                Excel
              </a-radio>
              <a-radio value="csv">
                CSV
              </a-radio>
            </a-radio-group>
          </p>
        </div>
      </div>
    </div>
    <div class="flex justify-content-center">
      <a-button
        type="primary"
        :disabled="!generateEnabled"
        :loading="loading"
        @click="generateReport"
      >
        Generate
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import {Modal, DatePicker, Button, Radio, notification} from "ant-design-vue";
import moment from "moment-timezone";
import api from "@/api";

export default {
  components: {
    'a-modal': Modal,
    'a-button': Button,
    'a-radio': Radio,
    'a-radio-group': Radio.Group,
    'a-date-picker': DatePicker
  },
  mixins: [api],
  data() {
    return {
      dateFrom: undefined,
      dateTo: undefined,
      reportFormat: 'excel',
      loading: false,
      visibleModal: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    visible(val) {
      this.visibleModal = val;
    }
  },
  computed: {
    dateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },

    serverDateFrom() {
      if (!this.dateFrom) {
        return null;
      }

      return this.dateFrom.format(this.serverDateFormat);
    },
    serverDateTo() {
      if (!this.dateTo) {
        return null;
      }

      return this.dateTo.format(this.serverDateFormat);
    },

    generateEnabled() {
      return this.dateFrom && this.dateTo;
    }
  },
  methods: {
    getCurrentDate() {
      return moment().endOf('day');
    },
    disabledStartDate(startValue) {
      const endValue = this.dateFrom;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.isAfter(endValue, 'day');
    },
    disabledEndDate(endValue) {
      const startValue = this.dateTo;
      if (!endValue || !startValue) {
        return false;
      }
      return endValue.isBefore(startValue, 'day');
    },
    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    generateReport() {
      this.loading = true;
      this.apiDownloadPayrollReportBasedOnRange(this.serverDateFrom, this.serverDateTo, this.reportFormat)
        .then((response) => {
          if (response.body.report_url) {
            window.open(response.body.report_url, '_blank');
          } else {
            this.showNotification(
              'warning',
              `Failed to download ${this.reportFormat.toUpperCase()} report for payroll`,
              'Please try again later'
            )
          }
        })
        .catch(() => {
          this.showNotification(
            'warning',
            `Failed to download ${this.reportFormat.toUpperCase()} report for payroll`,
            'Please try again later'
          )
        })
        .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
.content-view-block {
  width: 100%;
  max-width: unset;
}
</style>
