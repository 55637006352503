<template>
  <div>
    <reset-password
      :visible="resetPasswordModalVisible"
      :key="resetPasswordKey"
      @cancel="closeResetPasswordModal"
    />
    <div class="sign-in-form">
      <div class="epeople-logo-with-header">
        <img class="epeople-logo" src="@/assets/logo.png" alt="ePeople Logo"/>
        <h3 class="epeople-header">ePeople Time</h3>
      </div>
      <transition name="bounce">
        <a-alert
          v-if="authErrorMsg"
          type="error"
          :message="authErrorMsg"
        />
      </transition>
      <a-form class="sign-in-form-fields" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: 'Please enter your username' },
                  { type: 'email', message: usernameErrorMsg }
                ],
                validateTrigger: ['blur', 'change']
              }
            ]"
            :disabled="loading"
            placeholder="Username"
            :allow-clear="true"
          >
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)"/>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: 'Please enter your password' }],
                validateTrigger: ['blur', 'change']
              }
            ]"
            type="password"
            placeholder="Password"
            :disabled="loading"
          >
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)"/>
          </a-input>
        </a-form-item>
        <a-form-item>
          <div class="flex flex-column gap-2 justify-content-center mt-4">
            <a-button type="primary" html-type="submit" :disabled="hasErrors(form.getFieldsError()) || loading">
             Login
            </a-button>
            <a-button :disabled="loading" @click="startResetPassword">
              Forgot password?
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import {Form, Input, Button, Icon, Alert, notification} from 'ant-design-vue';
import api from "@/api";
import ResetPassword from "@/components/reset-password.vue";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default {
  components: {
    'reset-password': ResetPassword,
    'a-form': Form,
    'a-form-item': Form.Item,
    'a-input': Input,
    'a-button': Button,
    'a-icon': Icon,
    'a-alert': Alert
  },
  mixins: [api],
  data() {
    return {
      hasErrors,
      form: this.$form.createForm(this, { name: 'auth' }),
      usernameErrorMsg: 'The username should be email',
      loading: false,
      resetPasswordModalVisible: false,
      authErrorMsg: undefined,
      resetPasswordKey: 0
    }
  },
  methods: {
    handleSubmit(e) {
      this.loading = true;

      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.apiSignIn(values.username, values.password)
            .then((response) => {
              if (response.body.error_code != 0) {
                this.authErrorMsg = response.body.msg;
                setTimeout(() => {
                  this.authErrorMsg = undefined;
                }, 3000)
                return;
              }

              this.$router.push('/');
            })
            .finally(() => this.loading = false);
        } else {
          Object.keys(err).forEach((field) => {
            this[`${field}Error`] = err[field].errors[0].message;
            this.loading = false;
          });
        }
      });
    },
    startResetPassword() {
      ++this.resetPasswordKey;
      this.resetPasswordModalVisible = true;
    },
    closeResetPasswordModal() {
      this.resetPasswordModalVisible = false;
    }
  }
}
</script>

<style scoped>
.sign-in-form {
  width: 300px;
  margin: 10% auto;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);

  padding: 20px;
  border-radius: 32px;

  display: flex;
  flex-direction: column;
  gap: 32px;
}

.sign-in-form-fields {
  margin: 0 auto;
  width: 80%;
}

.ant-form-item:first-child {
  margin-bottom: unset;
}

.epeople-logo-with-header {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.epeople-logo {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}
.epeople-header {
  margin-top: unset;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
