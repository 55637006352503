<template>
  <div class="flex flex-column gap-4" v-if="list">
    <a-table
      size="small"
      :pagination="false"
      :columns="columns"
      :data-source="list"
    >
      <span slot="device" slot-scope="text, record">
        {{ formatDeviceType(record) }}
      </span>
      <span slot="date" slot-scope="text, record">
        {{ formatDateTime(record) }}
      </span>
      <span slot="action" slot-scope="text, record">
        {{ getActionLabel(record) }}
      </span>
      <span slot="in-wa" slot-scope="text, record">
        <a-tag :color="getTagColor(record)">{{ record.work_area ? 'YES' : 'NO' }}</a-tag>
      </span>
      <span slot="wa-name" slot-scope="text, record">
        {{ getWorkAreaName(record) }}
      </span>
    </a-table>
    <span>
      <b>*</b> {{ outsideWorkAreaNote }}
    </span>
  </div>
</template>

<script>
import {Table, Tag} from "ant-design-vue";
import Util from "@/util";

const statusTagColors = {
  GREEN: '#34db30',
  RED: '#ff4646'
}

const shiftActionEnum = Object.freeze({
  work_start: 'Work Start',
  work_end: 'Work End',
  lunch_start: 'Lunch Start',
  lunch_end: 'Lunch End',
  second_lunch_start: 'Second Lunch Start',
  second_lunch_end: 'Second Lunch End'
});

export default {
  components: {
    'a-table': Table,
    'a-tag': Tag
  },
  props: ['list'],
  data() {
    return {
      columns: [
        {
          title: 'Date and Time',
          scopedSlots: {customRender: 'date'},
          key: 'date',
          sorter: (a, b) => new Date(a.date) - new Date(b.date)
        },
        {
          title: 'Device',
          key: 'device',
          scopedSlots: {customRender: 'device'},
          sorter: (a, b) => a.device_type.localeCompare(b.device_type)
        },
        {
          title: 'Action',
          scopedSlots: {customRender: 'action'},
          key: 'action',
          sorter: (a, b) => {
            const actionA = a.action ? a.action : '';
            const actionB = b.action ? b.action : '';
            return actionA.localeCompare(actionB);
          }
        },
        {
          title: 'Address',
          dataIndex: 'address',
          sorter: (a, b) => a.address.localeCompare(b.address)
        },
        {
          title: 'In WA',
          scopedSlots: {customRender: 'in-wa'},
          key: 'inWa',
          sorter: (a, b) => (a.work_area ? 1 : 0) - (b.work_area ? 1 : 0)
        },
        {
          title: 'WA Name',
          key: 'waName',
          scopedSlots: {customRender: 'wa-name'},
          sorter: (a, b) => {
            const nameA = a.work_area ? a.work_area.name : '';
            const nameB = b.work_area ? b.work_area.name : '';

            return nameA.localeCompare(nameB);
          }
        }
      ],

      outsideWorkAreaNote: 'Actions with the label "Other" do not ' +
        'result in the creation of "Outside Work Area" type authorizations'
    }
  },
  methods: {
    formatDateTime(record) {
      return Util.convertDateTimeToCurrentTimeZoneFormattedString(record.date);
    },
    formatDeviceType(record) {
      switch (record.device_type) {
        case 'tablet':
          return 'Tablet';
        case 'cell_phone':
          return 'Cell Phone';
        default:
          return record.device_type;
      }
    },
    getTagColor(record) {
      return record.work_area ? statusTagColors.GREEN : statusTagColors.RED;
    },
    getWorkAreaName(record) {
      return record.work_area ? record.work_area.name : '-';
    },
    getActionLabel(record) {
      return shiftActionEnum[record.action] ?? 'Other';
    }
  }
}
</script>
