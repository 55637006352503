<template>
  <div class="signature-container">
    <div v-if="checkboxLabel" class="signature-checkbox">
      <a-checkbox
        v-if="!signatureDate"
        v-model="isChecked"
        class="signature-checkbox"
        @change="emitChecked"
      >
        {{ checkboxLabel }}
      </a-checkbox>
      <span v-else>{{ checkboxLabel }}</span>
    </div>
    <div class="signature-content">
      <span class="associate-name">{{ associateName }}</span>
      <a-button-group
        v-if="!availableSignature || !signatureDate"
        size="small"
        class="right-upper-corner"
      >
        <a-button :disabled="!!availableSignature" icon="redo" @click="clearSignature"/>
        <a-button :disabled="!!availableSignature" icon="left" @click="undoSignature"/>
        <a-button :disabled="!!availableSignature" icon="save" @click="saveSignature"/>
      </a-button-group>
      <div v-else class="right-upper-corner">
        <div><b>E-Signed</b></div>
        <div><b>{{ visualSignatureDateAndTime }}</b></div>
      </div>
      <VueSignaturePad
        class="signature-pad"
        width="280px"
        height="90px"
        ref="signaturePad"
      />
      <div class="signature-footer">
        <span class="associate-email">{{ associateEmail }}</span>
        <span class="associate-phone">{{ associatePhone }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import {Button, Checkbox} from 'ant-design-vue';

export default {
  components: {
    'a-button': Button,
    'a-button-group': Button.Group,
    'a-checkbox': Checkbox
  },
  emits: ['save-signature', 'checked'],
  data() {
    return {
      isChecked: false
    }
  },
  props: {
    associateName: {
      type: String,
      required: false
    },
    associateEmail: {
      type: String,
      required: false
    },
    associatePhone: {
      type: String,
      required: false
    },
    checkboxLabel: {
      type: String,
      required: false
    },
    availableSignature: {
      type: Object,
      required: false
    },
    signatureDate: {
      type: String,
      required: false
    }
  },
  computed: {
    dateTimeFormat() {
      return this.$store.state.applicationState.dateTimeFormat;
    },
    visualSignatureDateAndTime() {
      if (!this.signatureDate) {
        return '-';
      }

      return moment(this.signatureDate).format(this.dateTimeFormat);
    }
  },
  watch: {
    availableSignature: {
      deep: true,
      handler() {
        this.availableSignatureHandler();
      }
    }
  },
  methods: {
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.openSignaturePad();
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    saveSignature() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      this.$emit('save-signature', {isEmpty, data});
    },
    emitChecked() {
      this.$emit('checked', this.isChecked);
    },
    availableSignatureHandler() {
      if (this.availableSignature && this.availableSignature.image_url) {
          this.$refs.signaturePad.clearSignature();
          this.$refs.signaturePad.fromDataURL(this.availableSignature.image_url);
          this.$refs.signaturePad.lockSignaturePad();
        } else {
          this.$refs.signaturePad.clearSignature();
          this.$refs.signaturePad.openSignaturePad();
        }
    }
  },
  mounted() {
    this.availableSignatureHandler();
  }
}
</script>

<style scoped>
.signature-container {
  position: relative;
}

.signature-checkbox {
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
}

.signature-content {
  position: relative;
  border: 2px solid #D9D9D9;
  border-radius: 16px;
  width: 350px;
  height: 150px;
  padding: 30px 35px;
  margin-top: 10px;
}

.signature-content span {
  user-select: none;
}

.signature-pad {
  position: relative;
}

.associate-name {
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: bold;
  font-size: 16px;
  z-index: 10;
}

.right-upper-corner {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  text-align: right;
}

.signature-footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
}

.associate-email {
  font-size: 14px;
}

.associate-phone {
  font-size: 14px;
}
</style>
